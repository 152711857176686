import * as React from "react";
import { Route, Routes } from "react-router";
import { useIsAuthenticated } from "@azure/msal-react";

import Layout from "./components/Layout";
import Home from "./components/Home";

import "react-datepicker/dist/react-datepicker.css";

import "./custom.css";
import "./components/timesheet/timesheet.css";
import { ConfigHome } from "./components/config/ConfigHome";
import { ReportHome } from "./components/reports/ReportHome";
import { NotificationsPage } from "./components/NotificationsPage";

export default () => {
	return (
		<Layout>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/config" element={<ConfigHome />} />
				<Route path="/reports" element={<ReportHome />} />
				<Route path="/notifications" element={<NotificationsPage />} />
			</Routes>
		</Layout>
	);
};
