import { loginRequest, msalConfig } from "../auth/config";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

export async function authGet(url: string) {
	const accessToken = await getToken();

	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append("Authorization", bearer);

	const options = {
		method: "GET",
		headers: headers,
	};

	const response = await fetch(url, options);

	return response;
}

export async function authPost(url: string, data?: any) {
	const accessToken = await getToken();

	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append("Authorization", bearer);
	headers.append("Content-Type", "application/json");

	const options = {
		method: "POST",
		headers: headers,
		body: JSON.stringify(data),
	};

	const response = await fetch(url, options);

	return response;
}

export async function authPut(url: string, data?: any) {
	const accessToken = await getToken();

	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append("Authorization", bearer);
	headers.append("Content-Type", "application/json");

	const options = {
		method: "PUT",
		headers: headers,
		body: JSON.stringify(data),
	};

	const response = await fetch(url, options);

	return response;
}

export async function authDelete(url: string) {
	const accessToken = await getToken();

	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append("Authorization", bearer);

	const options = {
		method: "DELETE",
		headers: headers,
	};

	const response = await fetch(url, options);

	return response;
}

const getToken = async () => {
	const pca = new PublicClientApplication(msalConfig);
	const accounts = pca.getAllAccounts();
	const account = accounts[0];

	const request = {
		...loginRequest,
		account: account,
	};

	const resp = await pca.acquireTokenSilent(request);

	return resp.accessToken;
};
