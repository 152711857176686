import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

import { EmployeeInfoVm } from '../interfaces/interfaces';
import { authGet } from '../auth/authFetch';
import { MessageAction } from './message';

export interface UserStore {
    user?: EmployeeInfoVm 
}

interface RequestUserAction {
    type: 'REQUEST_USER'
}

interface ReceiveUserAction {
    type: 'RECEIVE_USER',
    user: EmployeeInfoVm
}

export type KnownAction = RequestUserAction | ReceiveUserAction | MessageAction;


export const actionCreators = {
    getUser: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (!getState().user.user) {
            authGet(`api/user/get`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) dispatch({ type: 'RECEIVE_USER', user: data });
                    else dispatch({ type: 'UPDATE_MESSAGE', message: data.message || 'Error occured getting user information' })
                });

            dispatch({ type: 'REQUEST_USER' });
        }
    }
}

const DefaultState: UserStore = {

}

export const reducer: Reducer<UserStore> = (state: UserStore | undefined, incomingAction: Action): UserStore => {

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_USER':
            return {
                ...state,
            };
        case 'RECEIVE_USER':
            return {
                ...state, user: action.user
            };
        default: {
            // @ts-ignore
            const exhaustiveCheck: never = action
        }
    }

    return state || DefaultState;
} 