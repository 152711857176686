import * as React from "react";
import {
	Collapse,
	Container,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	NavItem,
	NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import { SignOutButton } from "../auth/SignOut";
import * as AlertStore from "../store/alerts";
import * as DropdownStore from "../store/dropdowns";
import * as UserStore from "../store/user";
import { useDispatch } from "react-redux";
import { ApplicationState } from "../store";
import { MyAuthElement } from "../auth/WrappedAuthElement";
import { PermissionLevel } from "../interfaces/interfaces";
import { NotificationTray } from "./NotificationTray";

export const NavMenu = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const dispatch = useDispatch();

	const _toggle = () => {
		setIsOpen(!isOpen);
	};

	React.useEffect(() => {
		// @ts-ignore
		dispatch(UserStore.actionCreators.getUser());		
		// @ts-ignore
		dispatch(DropdownStore.actionCreators.get());
	}, []);

	const PowerUserEle = MyAuthElement([PermissionLevel.PowerUser]);

	return (
		<header>
			<Navbar
				className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
				light
			>
				<Container>
					<NavbarBrand tag={Link} to="/">
						Green Badge
					</NavbarBrand>
					<NavbarToggler onClick={_toggle} className="mr-2" />
					<Collapse
						className="d-sm-inline-flex flex-sm-row-reverse"
						isOpen={isOpen}
						navbar
					>
						<ul className="navbar-nav flex-grow">
							<NavItem>
								<NavLink tag={Link} className="text-dark" to="/">
									Timesheets
								</NavLink>
							</NavItem>							
							<NavItem>
								<NavLink tag={Link} className="text-dark" to="/reports">
									Reports
								</NavLink>
							</NavItem>
							<PowerUserEle componentType="span">
								<NavItem>
									<NavLink tag={Link} className="text-dark" to="/config">
										Configuration
									</NavLink>
								</NavItem>
							</PowerUserEle>
							<NotificationTray />
							<NavItem>
								<SignOutButton />
							</NavItem>
						</ul>
					</Collapse>
				</Container>
			</Navbar>
		</header>
	);
};
