import 'bootstrap/dist/css/bootstrap.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth/config';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import store from './store';

const msalInstance = new PublicClientApplication(msalConfig);

const root = createRoot(document.getElementById('root') as Element);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        {/*@ts-ignore */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

//root.render(
//    <>
//        { /*@ts-ignore */}
//        <BrowserRouter>
//            <App />
//        </BrowserRouter>
//    </>
//);

registerServiceWorker();
