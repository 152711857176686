import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";

import {
	IConfigurableDropdown,
	JobPhaseTask,
	LineItemDescription,
	ListItem,
	ToolEntity,
} from "../interfaces/interfaces";
import { authGet, authPost, authPut } from "../auth/authFetch";
import { MessageAction } from "./message";

const getJobs = async (url: string) => {
	const response = authGet(url)
		.then((res) => Promise.all([res.ok, res.json()]))
		.then(([resOk, data]) => {
			if (resOk) return data as JobPhaseTask[];
			else alert(data.message || "Error getting items");
			return [] as JobPhaseTask[];
		});
	return response;
};

const getTools = async (url: string) => {
	const response = authGet(url)
		.then((res) => Promise.all([res.ok, res.json()]))
		.then(([resOk, data]) => {
			if (resOk) return data as ToolEntity[];
			else alert(data.message || "Error getting items");
			return [] as ToolEntity[];
		});

	return response;
};

const getDescriptions = async () => {
	const response = authGet(`api/configuration/descriptions`)
		.then((res) => Promise.all([res.ok, res.json()]))
		.then(([resOk, data]) => {
			if (resOk) return data;
			else alert(data.message || "Error getting items");
			return [] as LineItemDescription[];
		});

	return response;
};

export interface DropdownState {
	jobs: JobPhaseTask[];
	descriptions: LineItemDescription[];
	tools: ToolEntity[];
}

export interface RequestDropdownsAction {
	type: "REQUEST_DROPDOWNS";
}

export interface ReceiveDropdownsAction {
	type: "RECEIVE_DROPDOWNS";
	jobs: JobPhaseTask[];
	descriptions: LineItemDescription[];
	tools: ToolEntity[];
}

export type KnownAction =
	| RequestDropdownsAction
	| ReceiveDropdownsAction
	| MessageAction;

export const actionCreators = {
	get: (): AppThunkAction<KnownAction> => (dispatch) => {
		Promise.all([
			getJobs(`api/configuration/jobphasetasks`),
			getTools(`api/configuration/tools`),
			getDescriptions(),
		]).then(([j, t, d]) => {
			dispatch({
				type: "RECEIVE_DROPDOWNS",
				jobs: j,
				descriptions: d,
				tools: t,
			});
		});

		dispatch({ type: "REQUEST_DROPDOWNS" });
	},
	addDescription:
		(jobId: number, name: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			authPost(`api/configuration/adddescription?jobId=${jobId}&name=${name}`)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const newDescriptions = getState().dropdowns.descriptions.slice();
						newDescriptions.push(data);
						dispatch({
							type: "RECEIVE_DROPDOWNS",
							descriptions: newDescriptions,
							tools: getState().dropdowns.tools,
							jobs: getState().dropdowns.jobs,
						});
						dispatch({ type: "UPDATE_MESSAGE", message: "Saved" });
					} else dispatch({ type: "UPDATE_MESSAGE", message: data.message });
				});
			dispatch({ type: "REQUEST_DROPDOWNS" });
		},
	addJob:
		(name: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			authPost(`api/configuration/job?name=${name}`)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const newJobs = getState().dropdowns.jobs.slice();
						newJobs.push(data);
						dispatch({
							type: "RECEIVE_DROPDOWNS",
							descriptions: getState().dropdowns.descriptions,
							tools: getState().dropdowns.tools,
							jobs: newJobs,
						});
						dispatch({ type: "UPDATE_MESSAGE", message: "Saved" });
					} else dispatch({ type: "UPDATE_MESSAGE", message: data.message });
				});
			dispatch({ type: "REQUEST_DROPDOWNS" });
		},
	addTool:
		(name: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			authPost(`api/configuration/tool?name=${name}`)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const newTools = getState().dropdowns.tools.slice();
						newTools.push(data);
						dispatch({
							type: "RECEIVE_DROPDOWNS",
							descriptions: getState().dropdowns.descriptions,
							tools: newTools,
							jobs: getState().dropdowns.jobs,
						});
						dispatch({ type: "UPDATE_MESSAGE", message: "Saved" });
					} else dispatch({ type: "UPDATE_MESSAGE", message: data.message });
				});
			dispatch({ type: "REQUEST_DROPDOWNS" });
		},
	editDescription:
		(
			id: number | string,
			field: string,
			value: any
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			authPut(`api/configuration/description?id=${id}&name=${name}`, {
				field: field,
				value: value,
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const newDescriptions = getState()
							.dropdowns.descriptions.slice()
							.map((x) =>
								x.id === id
									? ({
											id: data.id,
											name: data.name,
											jobPhaseTaskId: data.jobPhaseTaskId,
											billingRate: data.billingRate,
									  } as LineItemDescription)
									: x
							);
						dispatch({
							type: "RECEIVE_DROPDOWNS",
							descriptions: newDescriptions,
							tools: getState().dropdowns.tools,
							jobs: getState().dropdowns.jobs,
						});
						dispatch({ type: "UPDATE_MESSAGE", message: "Saved" });
					} else dispatch({ type: "UPDATE_MESSAGE", message: data.message });
				});
			dispatch({ type: "REQUEST_DROPDOWNS" });
		},
	editJob:
		(
			id: number | string,
			field: string,
			value: any
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			authPut(`api/configuration/job?id=${id}&name=${name}`, {
				field: field,
				value: value,
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const newJobs = getState()
							.dropdowns.jobs.slice()
							.map((x) =>
								x.id === id
									? ({
											id: data.id,
											name: data.name,
											type: data.type,
											lineItems: data.lineItems,
									  } as JobPhaseTask)
									: x
							);
						dispatch({
							type: "RECEIVE_DROPDOWNS",
							descriptions: getState().dropdowns.descriptions,
							tools: getState().dropdowns.tools,
							jobs: newJobs,
						});
						dispatch({ type: "UPDATE_MESSAGE", message: "Saved" });
					} else dispatch({ type: "UPDATE_MESSAGE", message: data.message });
				});
			dispatch({ type: "REQUEST_DROPDOWNS" });
		},
	editTool:
		(
			id: number | string,
			field: string,
			value: any
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			authPut(`api/configuration/tool?id=${id}&name=${name}`, {
				field: field,
				value: value,
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const newTools = getState()
							.dropdowns.tools.slice()
							.map((x) =>
								x.id === id
									? ({
											id: data.id,
											name: data.name,
											billableName: data.billableName,
											lineItems: data.lineItems,
											poLocation: data.poLocation,
									  } as ToolEntity)
									: x
							);
						dispatch({
							type: "RECEIVE_DROPDOWNS",
							descriptions: getState().dropdowns.descriptions,
							tools: newTools,
							jobs: getState().dropdowns.jobs,
						});
						dispatch({ type: "UPDATE_MESSAGE", message: "Saved" });
					} else dispatch({ type: "UPDATE_MESSAGE", message: data.message });
				});
			dispatch({ type: "REQUEST_DROPDOWNS" });
		},
};

const DefaultState: DropdownState = {
	jobs: [] as JobPhaseTask[],
	descriptions: [] as LineItemDescription[],
	tools: [] as ToolEntity[],
};

export const reducer: Reducer<DropdownState> = (
	state: DropdownState | undefined,
	incomingAction: Action
): DropdownState => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "REQUEST_DROPDOWNS":
			return {
				...(state || DefaultState),
			};
		case "RECEIVE_DROPDOWNS":
			return {
				...state,
				jobs: action.jobs,
				descriptions: action.descriptions,
				tools: action.tools,
			};
		default: {
			// @ts-ignore
			const exhaustiveCheck: never = action;
		}
	}

	return state || DefaultState;
};
