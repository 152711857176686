import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DirectorMonthlyReport, SupervisorTimesheetReport } from "../../interfaces/interfaces";

export interface ReportsState {
	supervisorReport: SupervisorTimesheetReport | undefined;
	selectedEndDate: string | undefined;
	selectedMonth: string | undefined;
	monthlyReport: DirectorMonthlyReport | undefined;
}

export const initialState: ReportsState = {
	supervisorReport: undefined,
	selectedEndDate: undefined,
	selectedMonth: undefined,
	monthlyReport: undefined,
};

const { actions: ReportsActions, reducer: ReportsReducer } = createSlice({
	name: "reports",
	initialState,
	reducers: {
		getDirectorMonthlyReport(_state, _action: PayloadAction<string>) { },
		getDirectorMonthlyReportSuccess(
			state,
			action: PayloadAction<DirectorMonthlyReport>
		) {
			state.monthlyReport = action.payload;
		},
		getSupervisorReports(_state, _action: PayloadAction<string>) {},
		getSupervisorReportsSuccess(
			state,
			action: PayloadAction<SupervisorTimesheetReport>
		) {
			state.supervisorReport = action.payload;
		},
		setSelectedDate(state, action: PayloadAction<string>) {
			state.selectedEndDate = action.payload;
		},
		setSelectedMonth(state, action: PayloadAction<string>) {
			state.selectedMonth = action.payload;
		}
	},
});

export { ReportsActions, ReportsReducer };
export default ReportsReducer;
