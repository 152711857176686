import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import cx from 'classnames';

//import { UserNotification, UserNotificationStatus, actionCreators } from '../store/notifications';
import { UserAlert } from '../store/alerts';


interface Props {
    notifications: UserAlert[],
    loading: boolean,
    fullPage: boolean,
}

export const NotificationStream = (props: Props) => {

    const { notifications, fullPage } = props;

    const dispatch = useDispatch();

    const hasNotifications = (notifications && notifications.length !== 0)

    const streamBody =
        <div>
            {!fullPage &&
                <div className='stream-tray-banner'><b>{notifications.length} Notification(s).</b>
                    <div className='banner-links'>
                        <Link to='/notifications'>View history</Link>
                        {/*{hasNotifications &&*/}
                        {/*    <React.Fragment>*/}
                        {/*        &#8226;*/}
                        {/*        <a onClick={() => { dispatch(actionCreators.dismiss(notifications.filter(x => x.status !== UserNotificationStatus.Dismissed).map(x => x.id))); return false }}>Dismiss all</a>*/}
                        {/*    </React.Fragment>*/}
                        {/*}*/}
                    </div>
                </div>
            }
            {hasNotifications ?
                notifications.map(x => <NotificationItem notification={x} dismissable={!fullPage} fullPage={fullPage} />) :
                <div className='notification-item'><b>No notifications to display</b></div>
            }
        </div>


    return (
        <div className={cx('notification-stream', fullPage ? 'fullpage' : 'tray', !hasNotifications && 'empty-stream')}>
            {streamBody}
        </div>
    )
}

export const NotificationItem = (props: {
    notification: UserAlert,
    dismissable: boolean,
    fullPage: boolean,
}) => {

    const { notification, dismissable, fullPage } = props;
    const [hovered, setHovered] = React.useState(false);

    const dispatch = useDispatch();

    //const _handleHover = (e: React.MouseEvent<HTMLElement>) => {
    //    if (notification.status === UserNotificationStatus.Unread && !fullPage) {
    //        dispatch(actionCreators.markAsRead([notification.id], false));
    //    }
    //    setHovered(true)
    //}

    return (
        <div className={cx('notification-item', (!fullPage && 'unread'))}
        //onMouseEnter={_handleHover} onMouseLeave={() => setHovered(false)}
        >
            {/*<div className='notification-timestamp'>{formatDateTime(notification.timestamp)}</div>*/}
            <div className='notification-message'><span>{notification.message}</span>
                {/*
                {/*{notification.linkUri !== '' ?
                {/*    <React.Fragment>
                {/*        &nbsp; &#8226; &nbsp;
                {/*        <Link to={notification.linkUri}>{notification.linkText}</Link>
                {/*    </React.Fragment> : []
                {
                */}
            </div>

            {/*
            {(hovered && dismissable) && <span className='fas fa-times' title='Dismiss this notification' onClick={() => dispatch(actionCreators.dismiss([notification.id]))}></span>}
            */}
        </div>
    )
}