import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

import {
  Timesheet,
  TimesheetLineItem,
  TimesheetEntry,
} from '../interfaces/interfaces';
import { authGet, authPost, authPut, authDelete } from '../auth/authFetch';
import { MessageAction } from './message';

export interface TimesheetStore {
  timesheet?: Timesheet;
  loading: boolean;
  saving: boolean;
}

interface RequestTimesheetAction {
  type: 'REQUEST_TIMESHEET';
}

interface CopyTimesheetAction {
  type: 'COPY_TIMESHEET';
}

interface UpdateTimesheetAction {
  type: 'UPDATE_TIMESHEET';
}

interface ReceiveTimesheetAction {
  type: 'RECEIVE_TIMESHEET';
  timesheet?: Timesheet;
}

export type KnownAction =
  | RequestTimesheetAction
  | ReceiveTimesheetAction
  | CopyTimesheetAction
  | UpdateTimesheetAction
  | MessageAction;

export const actionCreators = {
  getTimesheet:
    (id: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        if (
          getState().timesheet.timesheet === undefined ||
          // @ts-ignore
          getState().timesheet.timesheet.id !== id
        ) {
          authGet(`api/timesheet/get?id=${id}`)
            .then((res) => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
              if (resOk) dispatch({ type: 'RECEIVE_TIMESHEET', timesheet: data });
              else {
                dispatch({
                  type: 'RECEIVE_TIMESHEET',
                  timesheet: getState().timesheet.timesheet,
                });
                dispatch({
                  type: 'UPDATE_MESSAGE',
                  message: data.message || 'Error occured loading timesheet',
                });
              }
            });

          dispatch({ type: 'REQUEST_TIMESHEET' });
        }
      },
  addTimesheet:
    (
      startDate: string,
      employeeId: string | null
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        authPost(
          `api/timesheet/add?startDate=${startDate}&employeeId=${employeeId}`
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) dispatch({ type: 'RECEIVE_TIMESHEET', timesheet: data });
            else {
              dispatch({
                type: 'RECEIVE_TIMESHEET',
                timesheet: getState().timesheet.timesheet,
              });
              dispatch({
                type: 'UPDATE_MESSAGE',
                message: data.message || 'Error occured creating timesheet',
              });
            }
          });

        dispatch({ type: 'REQUEST_TIMESHEET' });
      },
  copyTimesheet:
    (
      id: number,
      startDate: string,
      employeeId: string | null
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        authPost(
          `api/timesheet/copy?id=${id}&startDate=${startDate}&employeeId=${employeeId}`
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) dispatch({ type: 'RECEIVE_TIMESHEET', timesheet: data });
            else {
              dispatch({
                type: 'RECEIVE_TIMESHEET',
                timesheet: getState().timesheet.timesheet,
              });
              dispatch({
                type: 'UPDATE_MESSAGE',
                message: data.message || 'Error occured copying timesheet',
              });
            }
          });

        dispatch({ type: 'REQUEST_TIMESHEET' });
      },
  submit: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    if (getState().timesheet.timesheet !== undefined) {
      // @ts-ignore
      authPut(`api/timesheet/submit?id=${getState().timesheet.timesheet.id}`)
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            dispatch({ type: 'RECEIVE_TIMESHEET', timesheet: data });
            dispatch({ type: 'UPDATE_MESSAGE', message: 'Saved' });
          } else {
            dispatch({
              type: 'RECEIVE_TIMESHEET',
              timesheet: getState().timesheet.timesheet,
            });
            dispatch({
              type: 'UPDATE_MESSAGE',
              message: data.message || 'Error occured submitting timesheet',
            });
          }
        });

      dispatch({ type: 'UPDATE_TIMESHEET' });
    }
  },
  addLineItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const { timesheet } = getState().timesheet;
    if (timesheet !== undefined) {
      authPost(`api/timesheet/addLine?id=${timesheet!.id}`)
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk)
            dispatch({
              type: 'RECEIVE_TIMESHEET',
              timesheet: {
                ...timesheet,
                lineItems: timesheet.lineItems.slice().concat([data]),
              } as Timesheet,
            });
          else {
            dispatch({
              type: 'RECEIVE_TIMESHEET',
              timesheet: getState().timesheet.timesheet,
            });
            dispatch({
              type: 'UPDATE_MESSAGE',
              message: data.message || 'Error occured updating timesheet',
            });
          }
        });

      dispatch({ type: 'UPDATE_TIMESHEET' });
    }
  },
  updateLineItem:
    (id: number, field: string, value: any): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const { timesheet } = getState().timesheet;
        if (timesheet !== undefined) {
          authPut(`api/timesheet/updateLine?id=${id}`, {
            field: field,
            value: value,
          })
            .then((res) => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
              if (resOk)
                dispatch({
                  type: 'RECEIVE_TIMESHEET',
                  timesheet: {
                    ...timesheet,
                    lineItems: timesheet.lineItems
                      .slice()
                      .map((x) => (x.id === id ? data : x)),
                  } as Timesheet,
                });
              else {
                dispatch({
                  type: 'RECEIVE_TIMESHEET',
                  timesheet: getState().timesheet.timesheet,
                });
                dispatch({
                  type: 'UPDATE_MESSAGE',
                  message: data.message || 'Error occured updating timesheet',
                });
              }
            });

          dispatch({ type: 'UPDATE_TIMESHEET' });
        }
      },
  updateLineItemOrder:
    (id: number, order: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const { timesheet } = getState().timesheet;
        if (timesheet !== undefined) {
          authPut(`api/timesheet/updateLineOrder?id=${id}&order=${order}`)
            .then((res) => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
              if (resOk)
                dispatch({
                  type: 'RECEIVE_TIMESHEET',
                  timesheet: {
                    ...timesheet,
                    lineItems: data as TimesheetLineItem[],
                  } as Timesheet,
                });
              else {
                dispatch({
                  type: 'RECEIVE_TIMESHEET',
                  timesheet: { ...timesheet },
                });
                dispatch({
                  type: 'UPDATE_MESSAGE',
                  message:
                    data.message ||
                    'There was an error updating the order of your timesheets',
                });
              }
            });

          dispatch({ type: 'UPDATE_TIMESHEET' });
        }
      },
  deleteLineItem:
    (id: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const { timesheet } = getState().timesheet;
        if (timesheet !== undefined) {
          authDelete(`api/timesheet/removeLine?id=${id}`)
            .then((res) => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
              if (resOk)
                dispatch({
                  type: 'RECEIVE_TIMESHEET',
                  timesheet: {
                    ...data,
                  } as Timesheet,
                });
              else {
                dispatch({
                  type: 'RECEIVE_TIMESHEET',
                  timesheet: getState().timesheet.timesheet,
                });
                dispatch({
                  type: 'UPDATE_MESSAGE',
                  message: data.message || 'Error occured updating timesheet',
                });
              }
            });

          dispatch({ type: 'UPDATE_TIMESHEET' });
        }
      },
  clearAllRows: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const { timesheet } = getState().timesheet;
    if (timesheet !== undefined) {
      authDelete(`api/timesheet/clearallrows?id=${timesheet.id}`)
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            dispatch({ type: 'RECEIVE_TIMESHEET', timesheet: data })
          }
          else {
            dispatch({
              type: 'RECEIVE_TIMESHEET',
              timesheet: getState().timesheet.timesheet,
            });
            dispatch({
              type: 'UPDATE_MESSAGE',
              message: data.message || 'Error occured updating timesheet',
            });
          }
        })
    }
  },
  updateEntry:
    (id: number, field: string, value: any): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const { timesheet } = getState().timesheet;
        if (timesheet !== undefined) {
          authPut(`api/timesheet/updateEntry?id=${id}`, {
            field: field,
            value: value,
          })
            .then((res) => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
              if (resOk)
                dispatch({
                  type: 'RECEIVE_TIMESHEET',
                  timesheet: {
                    ...timesheet,
                    lineItems: timesheet.lineItems.slice().map((x) => {
                      if (
                        !x.entries ||
                        !x.entries.length ||
                        x.entries.findIndex((y) => y.id === id) === -1
                      )
                        return x;
                      else
                        return {
                          ...x,
                          entries: x.entries
                            .slice()
                            .map((y) => (y.id === id ? data : y)),
                        };
                    }),
                  } as Timesheet,
                });
              else {
                dispatch({
                  type: 'RECEIVE_TIMESHEET',
                  timesheet: getState().timesheet.timesheet,
                });
                dispatch({
                  type: 'UPDATE_MESSAGE',
                  message: data.message || 'Error occured updating timesheet',
                });
              }
            });

          dispatch({ type: 'UPDATE_TIMESHEET' });
        }
      },
};

const DefaultState: TimesheetStore = {
  loading: false,
  saving: false,
};

export const reducer: Reducer<TimesheetStore> = (
  state: TimesheetStore | undefined,
  incomingAction: Action
): TimesheetStore => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'REQUEST_TIMESHEET':
    case 'COPY_TIMESHEET':
      return {
        ...(state || DefaultState),
        loading: true,
      };
    case 'UPDATE_TIMESHEET':
      return {
        ...(state || DefaultState),
        saving: true,
      };
    case 'RECEIVE_TIMESHEET':
      return {
        ...state,
        timesheet: action.timesheet,
        loading: false,
        saving: false,
      };
    default: {
      // @ts-ignore
      const exhaustiveCheck: never = action;
    }
  }

  return state || DefaultState;
};
