import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./config";

export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType: any) => {
        if (loginType === 'popup') {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/" // redirects the top level app after logout
            });
        } else if (loginType === 'redirect') {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    return <button className='btn btn-md ml-auto' onClick={() => handleLogin('redirect')}>Sign Out</button>
}
