import { authGet } from "../../auth/authFetch";
import {
	DirectorMonthlyReport,
	SupervisorTimesheetReport,
} from "../../interfaces/interfaces";

export const getDirectReportTimesheets = async (endDate: string) => {
	const response = await authGet(
		`api/report/GetSupervisorReports?endDate=${endDate}`
	);
	if (response.ok) {
		const body: SupervisorTimesheetReport = await response.json();
		return body;
	}
};

export const getDirectorMonthlyReport = async (date: string) => {
	const response = await authGet(`api/report/monthlyreport?month=${date}`);
	if (response.ok) {
		const body: DirectorMonthlyReport = await response.json();
		return body;
	}
};
