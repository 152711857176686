import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "../store/message";
import { ApplicationState } from "../store";

export const MessageBox = () => {
	const dispatch = useDispatch();
	const message = useSelector((s: ApplicationState) => s.message.message);
	const showMessage = useSelector(
		(s: ApplicationState) => s.message.showMessage
	);
	const isError = useSelector((s: ApplicationState) => s.message.isError);

	React.useEffect(() => {
		if (showMessage && !isError) {
			setTimeout(
				() =>
					//@ts-ignore
					dispatch(actionCreators.showMessage()),
				1000
			);
		}
	}, [showMessage, isError]);

	if (message) {
		let myStyle = {};
		if (isError) {
			myStyle = { backgroundColor: "red" };
			let innerContent: any;
			innerContent = message;
			return (
				<div
					style={myStyle}
					className={`state-message ${showMessage ? "" : "hidden"}`}
				>
					<b
						style={{
							cursor: "pointer",
							position: "absolute",
							top: "5px",
							right: "10px",
						}}
						onClick={() => {
							//@ts-ignore
							dispatch(actionCreators.clearMessage());
						}}
					>
						X
					</b>
					{innerContent}
				</div>
			);
		}
		return (
			<div className={`state-message ${showMessage ? "" : "hidden"}`}>
				{message}
			</div>
		);
	}
	return <div></div>;
};
