import * as React from 'react'
import './loader.css';

export class Loader extends React.Component<{ loading: boolean }, any> {
    
    public render() {
        //return <div></div>;
        if (this.props.loading) {
            return (
                <div id="loaderId" aria-busy="true" aria-label="Loading" role="progressbar">
                    <div className="swing">
                        <div className="swing-l"></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div className="swing-r"></div>
                    </div>
                    <div className="shadow">
                        <div className="shadow-l"></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div className="shadow-r"></div>
                    </div>
                </div>
            )
        }
        else return <div></div>;
    }
}