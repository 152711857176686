import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { StandardGridLoader } from './StandardGridLoader';

export const TimesheetLoader = () => {

    return <div>
        <div style={{ display: 'flex', width: '1000px', marginBottom: '20px' }}>
            <div style={{ padding: '0px 7px 0px 0px', width: '500px', height: '126px' }}>
                <StandardGridLoader rowCount={4} rowContentHeight={23.5} rowPadding={4} />
            </div>
            <div style={{ padding: '0px 0px 0px 7px', width: '500px', height: '126px' }}>
                <StandardGridLoader rowCount={4} rowContentHeight={23.5} rowPadding={4} />
            </div>
        </div>
        <StandardGridLoader rowCount={10} rowContentHeight={38} rowPadding={5} />
    </div>
}