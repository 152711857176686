import { Component } from "react";
import * as React from "react";
import * as download from "downloadjs";
import { Loader } from "./loaders/Loader";
import cx from "classnames";
import { useSelector } from "react-redux";
import { authGet, authPost } from "../auth/authFetch";

interface Props {
    actionPath: string;
    fileName?: string;
    buttonText?: string;    
    fileType: string;
    queryParams: any;
    inlineStyles?: any;
    httpMethod?: string;
    httpBody?: any;
    className?: string;
}

type State = { loading: boolean; message?: string };

export const downloadFileTask = (
    queryParams: any,
    actionPath: any,
    httpMethod: any,
    httpBody: any
) => {
    let url = actionPath + "?";
    const qString: any[] = [];
    Object.keys(queryParams).forEach((key: any) => {
        if (Array.isArray(queryParams[key])) {
            queryParams[key].forEach((val: any) =>
                qString.push(encodeURIComponent(key) + "=" + encodeURIComponent(val))
            );
        } else
            qString.push(
                encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])
            );
    });
    url = url + qString.join("&");

    const fetchTask =
        httpMethod === "POST" || httpMethod === "PUT"
            ? authPost(url, {
                credentials: "same-origin",
                method: httpMethod,
                body: JSON.stringify(httpBody),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            : authGet(url);

    return fetchTask;
};

export const getFileName = (header: string | null) => {
    let strFileName = "";
    if (header) strFileName = scrubContentDispositionForFilename(header);

    return strFileName;
};

export const FileDownloader = (props: Props) => {
    const [state, setState] = React.useState({ loading: false } as State);

    const _downloadFile = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        const { queryParams, actionPath, httpMethod, httpBody } = props;
        if (queryParams === undefined) {
            return null;
        }
        setState({
            ...state,
            loading: true,
        });

        const fetchTask = downloadFileTask(
            queryParams,
            actionPath,
            httpMethod,
            httpBody
        );

        fetchTask
            .then((res) => {
                return Promise.all([
                    res.ok,
                    res.blob(),
                    res.headers.get("Content-Disposition"),
                ]);
            })
            .then(([resOk, data, header]) => {
                if (resOk) {
                    let strFileName = "";
                    if (header) strFileName = scrubContentDispositionForFilename(header);
                    if (strFileName.length === 0) {
                        strFileName =
                            (props.fileName &&
                                props.fileName.replace(/[.:<>"/\\|?*]/g, "")) ||
                            "";
                    }
                    //@ts-ignore
                    if (download(data, strFileName)) {
                        setState({
                            ...state,
                            loading: false,
                        });
                    }
                    else {
                        setState({
                            ...state,
                            loading: false,
                            message: "Error downloading files",
                        });
                        alert("Error downloading files");
                    }
                } else {
                    setState({
                        ...state,
                        loading: false,
                        message: "Error downloading files",
                    });
                    alert("Error downloading files");
                }
            });
    };   

    const title = `Download ${props.fileType}`;
    return (
        <span
            style={props.inlineStyles}
            className={cx(
                "fas fa-download",
                props.className || "btn btn-sm btn-outline-dark"
            )}
            onClick={_downloadFile}
            title={title}
        >
            {props.buttonText}
            <Loader loading={state.loading} />
        </span>
    );
};

const scrubContentDispositionForFilename = (contentDisposition: string) => {
    // filename* should be prefered
    let result = "";
    if (contentDisposition.indexOf("filename*=") !== -1) {
        const split = contentDisposition.split("filename*=");
        result = split[split.length - 1];
        // filename* seems to ship with utc specification in front of the file name
        if (result.indexOf("UTF-") !== -1) {
            const quoteSplit = result.split("''");
            result = quoteSplit[quoteSplit.length - 1];
        }
    } else if (contentDisposition.indexOf("filename=") !== -1) {
        const split = contentDisposition.split("filename=");
        result = split[split.length - 1];
    }

    return decodeURI(result);
};
