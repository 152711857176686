import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  formatDate,
  formateMonthYear,
  sum,
  toMoney,
} from '../timesheet/Timesheet';
import { useDispatch } from 'react-redux';
import {
  monthlyReportSelector,
  ReportsActions,
  selectedEndDateSelector,
  selectedMonthSelector,
} from '../../store/reports';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FileDownloader } from '../FileDownloader';

const StyledColumn = styled.div({
  padding: '8px 16px',
  minHeight: '80vh',
});

const StyledContainer = styled.div({
  border: '1px solid rgb(140, 140, 140)',
  borderRadius: '0px 0px 2px 2px',
  width: '100%',
  margin: '10px 0px',
});

const StyledTableHeader = styled.div({
  padding: '12px 0px',
  borderBottom: '1px solid lightgray',
  '&:last-of-type': {
    borderBottom: 'none',
  },
  fontWeight: 'bold',
  backgroundColor: 'rgba(230, 230, 240, 0.3)',
  display: 'flex',
});

const StyledRow = styled.div({
  padding: '12px 0px',
  borderBottom: '1px solid lightgray',
  '&:last-of-type': {
    borderBottom: 'none',
  },
  display: 'flex',
});

const StyledHoursRow = styled.div<{ isRed: boolean }>(
  {
    padding: '12px 0px',
    borderBottom: '1px solid lightgray',
    '&:last-of-type': {
      borderBottom: 'none',
    },
    display: 'flex',
  },
  ({ isRed }) => ({ color: isRed ? 'rgb(237, 92, 87)' : undefined })
);

//export const DirectorReport: React.FC = () => {
export const DirectorReport = () => {
  //const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [showDetail, setShowDetail] = React.useState(true);
  const monthlyReport = useSelector(monthlyReportSelector);
  const selectedMonth = useSelector(selectedMonthSelector);
  const dispatch = useDispatch();

  const month = selectedMonth ? months[new Date(selectedMonth).getMonth()] : '';

  const _downloadExcel = () => {};

  return (
    <>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <DatePicker
          key={new Date().toISOString()}
          onChange={(date) => {
            if (date !== null) {
              dispatch(
                ReportsActions.getDirectorMonthlyReport(date.toISOString())
              );
            }
          }}
          placeholderText="Select a month"
          value={selectedMonth ? formateMonthYear(selectedMonth) : ''}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          //filterDate={(date) => date.getDate() === 1}
        />
        {monthlyReport && monthlyReport.lines && selectedMonth ? (
          <FileDownloader
            actionPath="api/report/monthlyreportexcel"
            queryParams={{ month: selectedMonth }}
            fileType="Excel Report"
            httpMethod="GET"
            buttonText="Download to Excel"
          />
        ) : (
          []
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'start' }}>
        <StyledColumn
          style={{
            borderRight: '1px solid lightgray',
            marginLeft: '-16px',
            width: '60%',
          }}
        >
          <h5>{month}</h5>
          {monthlyReport && (
            <StyledContainer>
              <>
                <StyledTableHeader>
                  <div className="my-col-2">Expense PO</div>
                  <div className="my-col-6">Tool / Description</div>
                  <div className="my-col-2">CAPEX</div>
                  <div className="my-col-2">OPEX</div>
                  <div className="my-col-2">OH</div>
                  <div className="my-col-2">Cost</div>
                  {showDetail && <div className="my-col-2">% of Capital</div>}
                  {showDetail && <div className="my-col-2">Indirect Add</div>}
                  {showDetail && <div className="my-col-2">Total Cost</div>}
                </StyledTableHeader>
                {monthlyReport.lines.map((x) => (
                  <StyledRow>
                    <div className="my-col-2">{x.toolPO}</div>
                    <div className="my-col-6">{x.toolOrDescription}</div>
                    <div className="my-col-2">{x.capexTotalHrs}</div>
                    <div className="my-col-2">{x.opexTotalHrs}</div>
                    <div className="my-col-2">{x.ohTotalHrs}</div>
                    <div className="my-col-2">{toMoney(x.cost, 2)}</div>
                    {showDetail && (
                      <div className="my-col-2">
                        {(x.percentOfCapital * 100).toFixed(2)}
                      </div>
                    )}
                    {showDetail && (
                      <div className="my-col-2">
                        {toMoney(x.indirectAdd, 2)}
                      </div>
                    )}
                    {showDetail && (
                      <div className="my-col-2">{toMoney(x.totalCost, 2)}</div>
                    )}
                  </StyledRow>
                ))}
              </>
            </StyledContainer>
          )}
        </StyledColumn>
        <StyledColumn style={{ width: '40%' }}>
          <h5>Invoice Summary Report</h5>
          {monthlyReport ? (
            <StyledContainer>
              <>
                <StyledTableHeader>
                  <div className="col-sm">Amount</div>
                  <div className="col-sm">Expense PO</div>
                </StyledTableHeader>
                {monthlyReport.invoiceRollup.rows.map((x) => (
                  <StyledRow key={x.name}>
                    <div className="col-sm">{toMoney(x.amount)}</div>
                    <div className="col-sm">{x.name}</div>
                  </StyledRow>
                ))}
                <StyledRow>
                  <div className="col-sm">
                    {toMoney(
                      monthlyReport.invoiceRollup.rows
                        .map((x) => x.amount)
                        .reduce(sum, 0)
                    )}
                  </div>
                  <div className="col-sm">Monthly Sort Total</div>
                </StyledRow>
              </>
            </StyledContainer>
          ) : (
            []
          )}
          {monthlyReport ? (
            <StyledContainer>
              <>
                <StyledTableHeader>
                  <div className="my-col-8">Job Title</div>
                  <div className="my-col-6">Total Hrs</div>
                  <div className="my-col-6">Total USD Totals</div>
                  {/*<div className="my-col-5">Invoice Line Item Name</div>*/}
                </StyledTableHeader>
                {monthlyReport.jobTitleRollup.rows.map((x) => (
                  <StyledRow>
                    <div className="my-col-8">{x.description}</div>
                    <div className="my-col-6">{x.hours}</div>
                    <div className="my-col-6">{toMoney(x.amount)}</div>
                    {/*<div className="my-col-5">{x.lineItemName}</div>*/}
                  </StyledRow>
                ))}
              </>
            </StyledContainer>
          ) : (
            []
          )}
          {monthlyReport ? (
            <StyledContainer>
              <>
                <StyledTableHeader>
                  <div className="col-sm">Employee Name</div>
                  <div className="col-sm">Total Hours</div>
                </StyledTableHeader>
                {monthlyReport.totalHoursRollup.rows.map((x) => (
                  <StyledHoursRow isRed={x.isLessHoursThanExpected}>
                    <div className="col-sm">{x.employeeName}</div>
                    <div className="col-sm">{x.totalHours}</div>
                  </StyledHoursRow>
                ))}
              </>
            </StyledContainer>
          ) : (
            []
          )}
        </StyledColumn>
      </div>
    </>
  );
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
