import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import * as cx from 'classnames';


//import { UserNotification, UserNotificationStatus, actionCreators } from '../store/notifications';
import { ApplicationState } from '../store/index';
import * as AlertStore from '../store/alerts';
import { NotificationStream } from './NotificationStream';
//import { NotificationStream } from './NotificationStream';
//import { useOutsideClickHandler } from '../helpers/customhooks';

export const NotificationTray = () => {

    const dispatch = useDispatch();
    const alertStore = useSelector((s: ApplicationState) => s.alerts);

    const [isOpen, setIsOpen] = React.useState(false);
    //const [connection, setConnection] = React.useState<null | HubConnection>(null);

    const undismissedNotifications = alertStore.alerts.filter(x => !x.dismissed);

    React.useEffect(() => {
        // @ts-ignore
        dispatch(AlertStore.actionCreators.getAlerts());
        //const connect = new HubConnectionBuilder()
        //    .withUrl('/hubs/notifications')
        //    .withAutomaticReconnect()
        //    .build();

        //setConnection(connect);
    }, [])

    //React.useEffect(() => {
    //    if (connection) {
    //        connection.start()
    //            .then(() => {
    //                connection.on("ReceiveMessage", (message) => {
    //                    dispatch(actionCreators.getNotifications(false));
    //                })
    //            })
    //            .catch((error) => console.log(error));
    //    }
    //}, [connection])
    

    const count = undismissedNotifications.length > 99 ? '99+' : undismissedNotifications.length;

    const wrapperRef = React.useRef(null);
    useOutsideClickHandler(wrapperRef, () => setIsOpen(false));

    return (
        <div className='notification-tray' ref={wrapperRef}>
            <div className='tray-button' onClick={() => setIsOpen(!isOpen)} title={!isOpen ? `You have ${count} unread notifications` : ''}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-inbox" viewBox="0 0 16 16">
                    <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
                </svg>
                {(count > 0) && <div className='notification-counter'>{count}</div>}
                {isOpen && <div className='stream-pointer'></div>}
            </div>
            {isOpen && <NotificationStream notifications={undismissedNotifications} loading={false} fullPage={false} />}
        </div>
    )
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideClickHandler = (ref: any, callback: () => void) => {
    React.useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}