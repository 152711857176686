import * as React from 'react';
import { Container } from 'reactstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { NavMenu } from './NavMenu';
import { SignInButton } from '../auth/SignIn';
import { MessageBox } from './MessageBox';

import './notifications.css';


export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render() {
        return (
            <React.Fragment>
                <AuthenticatedTemplate>
                    <MessageBox />
                    <NavMenu />
                    <div id='appBody'>
                        { /* @ts-ignore */}
                        {this.props.children}
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div style={{ margin: '100px 40%', textAlign: 'center' }}>
                        <h3>Green Badge</h3>
                        <hr />
                        <SignInButton />
                    </div>
                </UnauthenticatedTemplate>
            </React.Fragment>
        );
    }
}