import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import {
  ListItem,
  TimesheetLineItem,
  TimesheetStatus,
} from '../../interfaces/interfaces';
import { ApplicationState } from '../../store';
import { TimesheetLoader } from '../loaders/TimesheetLoader';
import { TimesheetLineComponent } from './TimesheetLine';
import { actionCreators as timesheetActions } from '../../store/timesheet';
import FilterableSelect from '../FilterableSelect';
import Modal from '../Modal';
import { authGet } from '../../auth/authFetch';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { DragDropContext, DropResult, Droppable } from '@hello-pangea/dnd';

const defaultCreateState = {
  show: false,
  copyId: 0,
  startDate: null as Date | null,
};

export const TimesheetComponent = () => {
  const timesheetStore = useSelector((s: ApplicationState) => s.timesheet);
  const userStore = useSelector((s: ApplicationState) => s.user);
  const dispatch = useDispatch();

  const { timesheet, loading, saving } = timesheetStore;
  const { user } = userStore;

  const [timesheets, setTimesheets] = React.useState([] as ListItem[]);
  const [directReports, setDirectReports] = React.useState([] as ListItem[]);
  const [selectedEmployeeId, setSelectedEmployeeId] = React.useState(
    null as string | null
  );

  const [showClearAllRowsModal, setShowClearRowsModal] = React.useState(false);
  const [createState, setCreateState] = React.useState(defaultCreateState);
  const [orderedLineItems, setOrderedLineItems] = React.useState<
    TimesheetLineItem[]
  >([]);
  const [timesheetBodyScrollTop, setTimesheetBodyScrollTop] = React.useState(0);

  const timesheetBodyRef = React.useRef<HTMLDivElement>(null);

  const _getTimesheets = () => {
    const url = selectedEmployeeId
      ? `api/timesheet/getall?employeeId=${selectedEmployeeId}`
      : `api/timesheet/getmine`;
    authGet(url)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setTimesheets(data);
        else alert(data.message || 'Error fetching timesheets');
      });
  };

  const _getDirectReports = () => {
    authGet(`api/employee/getmine`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setDirectReports(data);
        else alert(data.message || 'Error fetching director reports');
      });
  };

  const handleOnDragEnd = React.useCallback(
    (result: DropResult) => {
      const { destination, source, draggableId } = result;

      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      const foundLine = timesheet?.lineItems.find((x) => x.id == +draggableId);
      if (foundLine) {
        const newLineItems = Array.from(timesheet?.lineItems ?? []);
        newLineItems.splice(source.index, 1);
        newLineItems.splice(destination.index, 0, foundLine);

        setOrderedLineItems(newLineItems);
        dispatch(
          // @ts-ignore
          timesheetActions.updateLineItemOrder(
            foundLine.id,
            destination.index + 1
          )
        );
      }

      console.log(result);
    },
    [dispatch, timesheet]
  );

  React.useEffect(() => {
    _getTimesheets();
  }, [selectedEmployeeId]);

  //React.useEffect(() => {
  //    _getTimesheets();
  //}, [])

  React.useEffect(() => {
    setCreateState(defaultCreateState);
    setOrderedLineItems(timesheet?.lineItems ?? []);
  }, [timesheet]);

  React.useEffect(() => {
    _getDirectReports();
  }, [user]);

  const setScroll = () => {
    if (timesheetBodyRef.current && timesheetBodyRef.current.scrollTop) {
      setTimesheetBodyScrollTop(timesheetBodyRef.current.scrollTop);
    }
  };

  const dates: { d: string; total: number }[] = [];
  if (timesheet) {
    const dt = new Date(timesheet.startDate),
      end = new Date(timesheet.endDate);
    while (dt <= end) {
      const dtStr = formatDateMonthDayOfWeek(dt);
      const t = timesheet.lineItems
        .map((x) =>
          x.entries
            .filter((e) => new Date(e.date).getTime() === dt.getTime())
            .map((e) => e.hours)
            .reduce(sum, 0)
        )
        .reduce(sum, 0);
      dates.push({ d: dtStr, total: t });
      dt.setDate(dt.getDate() + 1);
    }
  }

  const clearAllRowsModal = showClearAllRowsModal ? (
    <Modal dialogStyle={{ maxWidth: '400px' }}>
      <div className='modal-body'>
        <h6>Are you sure you want to remove all rows for this timesheet? This cannot be reversed.</h6>
      </div>
      <div className='modal-footer'>
        
        <button className='btn btn-sm btn-outline-secondary' onClick={() => {
          // @ts-ignore
          dispatch(timesheetActions.clearAllRows());
          setTimeout(() => setShowClearRowsModal(false), 300);
        }
        }>Clear Rows</button>
        <button className='btn btn-sm btn-outline-secondary' onClick={() => setShowClearRowsModal(false)}>Cancel</button>
      </div>
    </Modal>
  ) : []

  const createModal = createState.show ? (
    <Modal modalClass="create-timesheet-modal">
      <div className="modal-header">
        <h4>Create New Timesheet</h4>
      </div>
      <div className="modal-body">
        <div style={{ display: 'flex' }}>
          <b style={{ width: '120px' }}>Start Date*</b>
          <DatePicker
            key={(createState.startDate || new Date()).toISOString()}
            onChange={(date) => {
              setCreateState({ ...createState, startDate: date });
            }}
            value={formatDate(createState.startDate)}
            placeholderText="Select a start Date"
            filterDate={(date) => date.getDay() === 0}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <b style={{ width: '120px' }}>Copy From</b>
          <FilterableSelect
            id="copy-timesheet-id"
            items={timesheets}
            onChange={(id) =>
              setCreateState({ ...createState, copyId: parseInt(id) })
            }
          />
        </div>
      </div>
      <div className="modal-footer">
        {createState.copyId === 0 ? (
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              dispatch(
                //@ts-ignore
                timesheetActions.addTimesheet(
                  (createState.startDate || new Date()).toISOString(),
                  selectedEmployeeId
                )
              );
              setTimeout(() => _getTimesheets(), 1500);
            }}
            disabled={createState.startDate === null}
          >
            Create
          </button>
        ) : (
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              dispatch(
                //@ts-ignore
                timesheetActions.copyTimesheet(
                  createState.copyId,
                  (createState.startDate || new Date()).toISOString(),
                  selectedEmployeeId
                )
              );
              setTimeout(() => _getTimesheets(), 1500);
            }}
            disabled={createState.startDate === null}
          >
            Copy
          </button>
        )}
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => setCreateState({ ...createState, show: false })}
        >
          Cancel
        </button>
      </div>
    </Modal>
  ) : (
    []
  );

  const detail = loading ? (
    <TimesheetLoader />
  ) : timesheet ? (
    <>
      <div id="top-level-info">
        <div>
          <div className="info-row">
            <b>Employee</b>
            <label>{timesheet.employee.fullName}</label>
          </div>
          <div className="info-row">
            <b>WWID</b>
            <label>{timesheet.employee.employeeNumber}</label>
          </div>
          <div className="info-row">
            <b>Title</b>
            <label>{timesheet.employee.title}</label>
          </div>
          <div className="info-row">
            <b>Start Date</b>
            <label>{formatDate(timesheet.startDate)}</label>
          </div>
        </div>
        <div>
          <div className="info-row">
            <b>Total Hrs</b>
            <label>
              {timesheet.lineItems
                .map((l) => l.entries.map((e) => e.hours).reduce(sum, 0))
                .reduce(sum, 0)}
            </label>
          </div>
          <div className="info-row">
            <b>Status</b>
            <label>{statusToText(timesheet.status)}</label>
          </div>
          <div className="info-row">
            <b>Submitted</b>
            <label>{formatDate(timesheet.submittedDate)}</label>
          </div>
          <div className="info-row">
            <b>End Date</b>
            <label>{formatDate(timesheet.endDate)}</label>
          </div>
        </div>
      </div>
      <div className="timesheet">
        <div className="timesheet-header">
          <div className="my-col-3">
            <b>Job/Phase/Task</b>
          </div>
          <div className="my-col-2">
            <b>Tool / Entity</b>
          </div>
          <div className="my-col-4">
            <b>Description</b>
          </div>
          {dates.map((x) => (
            <div className="my-col-1" key={x.d}>
              <b style={{ textAlign: 'center', display: 'block' }}>{x.d}</b>
              <b style={{ textAlign: 'center', display: 'block' }}>
                {' '}
                {x.total}
              </b>
            </div>
          ))}
          <div className="my-col-3"></div>
          <div className="my-col-3" style={{ display: 'flex', width: '183px', padding: '0px' }}>
            {/* @ts-ignore */}
            <button
              className="btn btn-sm btn-primary"
              disabled={timesheet.status === TimesheetStatus.Posted}
              //@ts-ignore
              onClick={() => dispatch(timesheetActions.submit())}
            >
              Submit
            </button>
            &nbsp;
            <button
              className="btn btn-sm btn-outline-secondary"
              disabled={timesheet.status === TimesheetStatus.Posted}
              //@ts-ignore
              onClick={() => setShowClearRowsModal(true)}
            >
              Clear All Rows
            </button>
          </div>
          {/*<div className="my-col-1" style={{ marginRight: '6px' }}></div>*/}
        </div>
        <DragDropContext onDragEnd={(result) => handleOnDragEnd(result)}>
          <div
            ref={timesheetBodyRef}
            onScroll={setScroll}
            className="timesheet-body custom-scrollbar"
          >
            <Droppable droppableId="timesheet">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {orderedLineItems.map((x, idx) => (
                    <TimesheetLineComponent
                      key={x.id}
                      index={idx}
                      id={x.id.toString()}
                      bodyScrollTop={timesheetBodyScrollTop}
                      line={x}
                      locked={timesheet.status === TimesheetStatus.Posted}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {/* @ts-ignore */}
            <button
              style={{ margin: '10px 7px' }}
              className="btn btn-sm btn-outline-primary"
              //@ts-ignore
              onClick={() => dispatch(timesheetActions.addLineItem())}
            >
              Add Line Item
            </button>
          </div>
        </DragDropContext>
      </div>
    </>
  ) : (
    []
  );

  return (
    <div className="timesheet-container">
      {createModal}
      {clearAllRowsModal}
      <div id="top-level-controls">
        {user ? (
          <>
            {user.isSupervisor ? (
              <>
                <b style={{ lineHeight: '2.5' }}>Select Employee</b> &nbsp;
                <FilterableSelect
                  id="timesheet-select"
                  items={directReports}
                  onChange={(id) => {
                    setSelectedEmployeeId(id || null);
                  }}
                  allowEmpty
                />
              </>
            ) : (
              []
            )}
            <b style={{ lineHeight: '2.5' }}>Select Timesheet</b> &nbsp;
            <FilterableSelect
              id="timesheet-select"
              items={timesheets}
              onChange={(id) => {
                //@ts-ignore
                dispatch(timesheetActions.getTimesheet(parseInt(id)));
              }}
            />
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => setCreateState({ ...createState, show: true })}
            >
              + Create New
            </button>
          </>
        ) : (
          <StandardGridLoader
            rowCount={1}
            rowContentHeight={32.5}
            rowPadding={5}
          />
        )}
      </div>
      <hr />
      {detail}
    </div>
  );
};

export const formatDateMonthDayOfWeek = (dt: Date) => {
  if (typeof dt === 'string') dt = new Date(dt);
  return dt
    ? DaysOfWeek[dt.getDay()].substr(0, 3) + ' ' + formatDateMonthDay(dt)
    : '';
};

export const formatDateMonthDay = (dt: Date) => {
  if (typeof dt === 'string') dt = new Date(dt);
  return dt
    ? `${(dt.getMonth() + 1).toString()}/${dt
      .getDate()
      .toString()
      .padStart(2, '0')}`
    : '';
};

export const formatDate = (dt?: Date | null) => {
  if (typeof dt === 'string') dt = new Date(dt);
  return dt ? `${formatDateMonthDay(dt)}/${dt.getFullYear()}` : '';
};

export const formateMonthYear = (dt: Date | string) => {
  if (typeof dt === 'string') dt = new Date(dt);
  return `${(dt.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${dt.getFullYear()}`;
};

export const toMoney = (val: string | number, pts?: number) => {
  val = typeof val === 'number' ? val : parseInt(val);
  let n = (Math.round(val * 100) / 100).toString();
  const decimals = pts || 0,
    d = '.',
    t = ',',
    s = val < 0 ? '-' : '',
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(decimals)))),
    m = parseInt(i);
  let j: any;
  j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    '$' +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (decimals
      ? d +
      Math.abs(val - m)
        .toFixed(decimals)
        .slice(2)
      : '')
  );
};

export const sum = (a: number, b: any) => {
  return a + b;
};

const statusToText = (s: TimesheetStatus) => {
  switch (s) {
    case TimesheetStatus.Pending:
      return 'Pending';
    case TimesheetStatus.Submitted:
      return 'Submitted';
    case TimesheetStatus.Posted:
      return 'Posted';
  }
};

const DaysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
