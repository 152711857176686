import * as React from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../store';
import * as UserStore from '../store/user'
import { hasRole, isUserById } from './authHelpers';

interface AuthEleProps {
    class?: string,
    style?: object,
    componentType?: string,
    children?: React.ReactNode
}

type MyAuthEleProps =
    AuthEleProps
    & UserStore.UserStore

export const MyAuthElement = (allowedRoles: any, allowedIds?: string[], allowedSubs?: string[]) => {
    class AuthElement extends React.Component<MyAuthEleProps, any> {
        render() {
            const { componentType, user } = this.props;
            const className = this.props.class || '',
                style = this.props.style || {},
                component = componentType || 'div';

            if (user && (hasRole(user, allowedRoles) || isUserById(user, allowedIds || ""))) {
                return React.createElement(
                    component,
                    {
                        className: className,
                        style: style,
                    } /*as Element*/,
                    this.props.children
                )
                //return <div className={this.props.class}>{this.props.children}</div>
            } else {
                return React.createElement(component)
            }
        }
    }
    return connect<UserStore.UserStore, any, AuthEleProps>(
        //@ts-ignore
        (state: ApplicationState) => state.user,
        {}
    )(AuthElement) //as typeof AuthElement
}