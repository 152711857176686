//import { myStore } from '../storeProvider';

import { EmployeeInfoVm, PermissionLevel } from "../interfaces/interfaces";

export interface User {
    name: string;
    roles: string[];
    rights: string[];
    subscriptions: string[];
}

export const isAuthenticated = (user: any) => user !== undefined;

export const isUser = (user: any, userName: string) =>
    user ? userName.toUpperCase() == user.email.toUpperCase() : false;

export const hasRole = (user: EmployeeInfoVm, roles: PermissionLevel[]) => {
    if (user === undefined || user.permissions === undefined) return false;
    return roles && roles.length
        ? roles.some((role: PermissionLevel) => user.permissions.indexOf(role) !== -1)
        : true;
};

export const isUserById = (user: any, userIds: any) => {
    if (user === undefined) return false;
    // if no userId was passed then that indicates there are no users with explicit authorization
    return userIds && userIds.length
        ? userIds.some((id: any) => user.userId === id)
        : false;
};