import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";

export interface MessageState {
	message?: string;
	showMessage: boolean;
	isError: boolean;
}

export interface MessageAction {
	type: "UPDATE_MESSAGE";
	message?: string;
}

export interface ShowMessageAction {
	type: "SHOW_MESSAGE";
	showMessage: boolean;
}

export type KnownAction = MessageAction | ShowMessageAction;

export const actionCreators = {
	setMessage:
		(message: string): AppThunkAction<KnownAction> =>
		(dispatch) => {
			dispatch({ type: "UPDATE_MESSAGE", message: message });
		},
	clearMessage: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: "UPDATE_MESSAGE", message: undefined });
	},
	showMessage:
		(showMessage: boolean): AppThunkAction<KnownAction> =>
		(dispatch) => {
			dispatch({ type: "SHOW_MESSAGE", showMessage: showMessage });
		},
};

const DefaultState: MessageState = {
	message: undefined,
	showMessage: false,
	isError: false,
};

export const reducer: Reducer<MessageState> = (
	state: MessageState = DefaultState,
	incomingAction: KnownAction
): MessageState => {
	const action = incomingAction;
	switch (action.type) {
		case "UPDATE_MESSAGE":
			return {
				...state,
				message: action.message,
				showMessage: action.message ? true : false,
				isError:
					action.message && action.message.toLowerCase().indexOf("error") === 0
						? true
						: false,
			};
		case "SHOW_MESSAGE":
			return {
				...state,
				showMessage: action.showMessage,
			};
		default: {
			// @ts-ignore
			const exhaustiveCheck: never = action;
		}
	}

	return state;
};
