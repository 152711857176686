import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import * as cx from 'classnames';


//import { UserNotification, UserNotificationStatus, actionCreators } from '../store/notifications';
import { ApplicationState } from '../store/index';
import * as AlertStore from '../store/alerts';
import { NotificationStream } from './NotificationStream';

export const NotificationsPage = () => {

    const dispatch = useDispatch();
    const alertStore = useSelector((s: ApplicationState) => s.alerts);

    const [isOpen, setIsOpen] = React.useState(false);
    //const [connection, setConnection] = React.useState<null | HubConnection>(null);

    const undismissedNotifications = alertStore.alerts.filter(x => !x.dismissed);

    React.useEffect(() => {
        // @ts-ignore
        dispatch(AlertStore.actionCreators.getAlerts());
        //const connect = new HubConnectionBuilder()
        //    .withUrl('/hubs/notifications')
        //    .withAutomaticReconnect()
        //    .build();

        //setConnection(connect);
    }, [])

    return (
        <div>
            <h5>My Notifications</h5>
            <hr />
            <NotificationStream notifications={undismissedNotifications} loading={false} fullPage={true} />
        </div>

    )
}