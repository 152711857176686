import * as React from "react";
import { useDispatch } from "react-redux";
import { authDelete, authGet, authPost, authPut } from "../../auth/authFetch";
import { EmployeeInfoVm, EmployeeStatus, IConfigurableDropdown, ListItem } from "../../interfaces/interfaces";
import FilterableSelect from "../FilterableSelect";

const KnownPermissions = [[9, "Power User"]];

export const EmployeeConfig = () => {
  const dispatch = useDispatch();

  const [employee, setEmployee] = React.useState(null as EmployeeInfoVm | null);
  const [employees, setEmployees] = React.useState([] as ListItem[]);
  const [jobTitles, setJobTitles] = React.useState([] as IConfigurableDropdown[]);

  const _getEmployeeDetail = (id: string) => {
    authGet(`api/employee/detail?id=${id}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setEmployee(data);
        else alert("Error getting employee");
      });
  };

  const _getEmployees = () => {
    authGet(`api/employee/getall`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setEmployees(data);
        else alert("Error getting employees");
      });
  };

  const _getJobTitles = () => {
    authGet(`api/configuration/jobtitles`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setJobTitles(data);
        else alert("Error getting job titles");
      });
  }

  const _add = () => {
    const form = document.querySelector("#new-employee") as HTMLDivElement;
    const inputs = form.querySelectorAll(
      'input[type="text"], input[type="checkbox"], .filterable-select input[type="hidden"], select'
    );
    // @ts-ignore
    const e: any = {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      alternateEmail: "",
      employeeNumber: "",
      title: "",
      supervisorId: "",
      status: 1,
      permissions: [] as {
        id: number;
        employeeId: number;
        permissionLevel: number;
      }[],
    } as EmployeeInfoVm;
    let valid = true;
    const supervisorId = form.querySelector(
      '.filterable-select input[type="hidden"]'
    ) as HTMLInputElement;
    e.supervisorId = supervisorId.value;

    [].forEach.call(inputs, (i: HTMLInputElement) => {
      i.classList.remove("error");
      if (i.type === "checkbox") {
        if (i.checked)
          e.permissions.push({
            id: 0,
            employeeId: 0,
            permissionLevel: parseInt(i.value),
          });
      } else {
        if (i.name === "") return;
        if (i.value === "") {
          valid = false;
          i.classList.add("error");
        } else e[i.name] = i.value;
      }
    });

    if (!valid) {
      alert("All fields are required");
    } else {
      authPost(`api/employee/add`, e)
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            [].forEach.call(inputs, (i: HTMLInputElement) => {
              i.value = "";
              i.checked = false;
            });
            supervisorId.value = "";
            setEmployees(
              employees.concat([
                { id: data.id, label: data.fullName } as ListItem,
              ])
            );
            dispatch({ type: "UPDATE_MESSAGE", message: "Saved" });
          } else dispatch({ type: "UPDATE_MESSAGE", message: data.message });
        });
    }
  };

  const _handleEvent = (
    e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const field = e.currentTarget.name,
      value =
        e.currentTarget.type === "checkbox"
          ? (e.currentTarget as HTMLInputElement).checked
            ? 1
            : 0
          : e.currentTarget.value;

    _update(field, value);
  };

  const _togglePermission = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!employee) return;
    const level = e.currentTarget.value,
      on = e.currentTarget.checked;
    const request = on
      ? authPost(
        `api/employee/addpermission?id=${employee.id}&permission=${level}`
      )
      : authDelete(
        `api/employee/removepermission?id=${employee.id}&permission=${level}`
      );

    request
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setEmployee(data);
        else alert(data.message);
      });
  };

  const _update = (field: string, value: any) => {
    if (employee === null) return;

    authPut(`api/employee/update?id=${employee.id}`, {
      field: field,
      value: value,
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setEmployee(data);
          dispatch({ type: "UPDATE_MESSAGE", message: "Saved" });
        } else alert("Error updating employee");
      });
  };

  React.useEffect(() => {
    _getEmployees();
    _getJobTitles();
  }, []);

  return (
    <div className="config-content employee-config">
      <div style={{ display: "flex" }}>
        <div
          className="config-tile"
          style={{ width: "50%", marginRight: "10px" }}
        >
          <div style={{ display: "flex", height: "26px" }}>
            <h5 style={{ display: "inline-block", marginRight: "20px" }}>
              Edit Employee Details
            </h5>
            <FilterableSelect
              id="employee-select"
              items={employees}
              onChange={(id) => _getEmployeeDetail(id)}
            />
          </div>
          <hr />
          {employee === null ? (
            <i>Select an employee to continue</i>
          ) : (
            <div className="my-form" key={employee.id}>
              <div className="form-group">
                <b>First Name</b>
                <input
                  className="form-control"
                  onBlur={_handleEvent}
                  defaultValue={employee.firstName}
                  name="firstName"
                />
              </div>
              <div className="form-group">
                <b>Last Name</b>
                <input
                  className="form-control"
                  onBlur={_handleEvent}
                  defaultValue={employee.lastName}
                  name="lastName"
                />
              </div>
              <div className="form-group">
                <b>CSG Email</b>
                <input
                  className="form-control"
                  onBlur={_handleEvent}
                  defaultValue={employee.email}
                  name="email"
                  disabled
                />
              </div>
              <div className="form-group">
                <b>Intel Email</b>
                <input
                  className="form-control"
                  onBlur={_handleEvent}
                  defaultValue={employee.alternateEmail}
                  name="alternateEmail"
                />
              </div>
              <div className="form-group">
                <b>Job Title</b>
                <select name='jobTitleId' className='form-control' defaultValue={employee.jobTitleId} onChange={_handleEvent}>
                  {jobTitles.map(x => <option className='form-control' value={x.id}>{x.name}</option>)}
                </select>
              </div>
              <div className="form-group">
                <b>WWID</b>
                <input
                  className="form-control"
                  onBlur={_handleEvent}
                  defaultValue={employee.employeeNumber}
                  name="employeeNumber"
                />
              </div>
              <div className="form-group">
                <b>Status</b>
                <select name='status' defaultValue={employee.status} onChange={_handleEvent} className='form-control'>
                  <option value={EmployeeStatus.Active} className='form-control'>Active</option>
                  <option value={EmployeeStatus.Inactive} className='form-control'>Inactive</option>
                </select>
              </div>
              <div className="form-group">
                <b>Supervisor</b>
                <FilterableSelect
                  id="supervisor-select"
                  items={employees}
                  defaultVal={employee.supervisorId}
                  onChange={(id) => _update("supervisorId", id)}
                />
              </div>
              <div className="form-group">
                <b>Permissions</b>
                <div>
                  {KnownPermissions.map((x) => (
                    <div style={{ marginRight: "15px" }}>
                      <input
                        type="checkbox"
                        name="permissions"
                        value={x[0]}
                        defaultChecked={employee.permissions.some(
                          (p) => p === x[0]
                        )}
                        onChange={_togglePermission}
                      />{" "}
                      {x[1]}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="config-tile" style={{ width: "50%" }}>
          <div style={{ height: "26px" }}>
            <h5>Add New Employee</h5>
          </div>
          <hr />
          <div className="my-form" id="new-employee">
            <div className="form-group">
              <b>First Name</b>
              <input className="form-control" name="firstName" type="text" />
            </div>
            <div className="form-group">
              <b>Last Name</b>
              <input className="form-control" name="lastName" type="text" />
            </div>
            <div className="form-group">
              <b>CSG Email</b>
              <input className="form-control" name="email" type="text" />
            </div>
            <div className="form-group">
              <b>Intel Email</b>
              <input
                className="form-control"
                name="alternateEmail"
                type="text"
              />
            </div>
            <div className="form-group">
              <b>Title</b>
              <select name='jobTitleId' className='form-control' defaultValue={6}>
                {jobTitles.map(x => <option className='form-control' value={x.id}>{x.name}</option>)}
              </select>
            </div>
            <div className="form-group">
              <b>WWID</b>
              <input
                className="form-control"
                name="employeeNumber"
                type="text"
              />
            </div>
            <div className="form-group">
              <b>Supervisor</b>
              <FilterableSelect
                id="supervisor-select-new"
                items={employees}
                onChange={(id) => _update("supervisorId", id)}
              />
            </div>
            <div className="form-group">
              <b>Permissions</b>
              <div>
                {KnownPermissions.map((x) => (
                  <div style={{ marginRight: "15px" }}>
                    <input type="checkbox" value={x[0]} name="permissions" />{" "}
                    {x[1]}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <button className="btn btn-sm btn-primary" onClick={_add}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
