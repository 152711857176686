import * as React from "react";
import { MyAuthElement } from "../../auth/WrappedAuthElement";
import { PermissionLevel } from "../../interfaces/interfaces";
import { TabLink, Tabs, TabContent } from "../tabs";

import "./config.css";
import { EmployeeConfig } from "./EmployeeConfig";
import { JobsConfig } from "./JobsConfig";
import { ToolsConfig } from "./ToolsConfig";

export const ConfigHome = () => {
	const PowerUserEle = MyAuthElement([PermissionLevel.PowerUser]);

	return (
		<div className="config-home">
			<Tabs name="config-tabs">
				<div className="tab-links">
					<TabLink to="jobs">
						<span>Jobs</span>
					</TabLink>
					<TabLink to="tools">
						<span>Tools</span>
					</TabLink>
					<TabLink to="employees">
						<span>Employees</span>
					</TabLink>
				</div>
				<div className="content">
					<TabContent for="jobs">
						<JobsConfig />
					</TabContent>
					<TabContent for="tools">
						<ToolsConfig />
					</TabContent>
					<TabContent for="employees">
						<EmployeeConfig />
					</TabContent>
				</div>
			</Tabs>
		</div>
	);
};
