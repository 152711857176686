import * as msal from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        clientId: '045efe02-27ed-490e-a725-d41663ccbf7c',
        authority: 'https://login.microsoftonline.com/32cb5add-5aee-4fa6-9230-04ab67ef29cb',
        //redirectUri: "http://localhost:3000"
    },
    cache: {
        cacheLocation: "localStorage", // "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequest = {
    scopes: ["api://045efe02-27ed-490e-a725-d41663ccbf7c/IntelTimekeeper.ReadWrite"]
}
