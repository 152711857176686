import * as React from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { AlertPopup } from './AlertPopup';
import { TimesheetComponent } from './timesheet/Timesheet';


const Home = () => {

    //const alertStore = useSelector((s: ApplicationState) => s.alerts);

    //const undismissedAlerts = alertStore.alerts.filter(x => !x.dismissed);

    return (
        <div>
            <TimesheetComponent />
        </div>
    );
};

export default connect()(Home);
