import * as React from 'react';
import { debounce } from 'throttle-debounce';
import { actionCreators as timesheetActions } from '../../store/timesheet';
import { actionCreators as messageActions } from '../../store/message';
import { useDispatch } from 'react-redux';
import { TimesheetEntry } from '../../interfaces/interfaces';
import PointedPopup from '../PointedPopup';
import cx from 'classnames';

export const TimesheetEntryComponent = (props: { entry: TimesheetEntry, locked: boolean }) => {

  const { entry, locked } = props;
  const [valid, setValid] = React.useState(true);

  const [newComments, setNewComments] = React.useState(props.entry.comments);
  const [newHours, setNewHours] = React.useState(props.entry.hours.toString());

  const dispatch = useDispatch();

  const _save = React.useCallback((field: string, value: string) => {
    //alert('entering debounced method');
    // @ts-ignore
    dispatch(timesheetActions.updateEntry(entry.id, field, value));
  }, [])

  const memoized_debounce = React.useMemo(() => {
    return debounce(750, _save);
  }, [_save]);

  // When they change the value we need to save the new value in state and check for validation
  const _handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const field = e.currentTarget.name, value = e.currentTarget.value;

    if (field === 'hours') setNewHours(value)
    else if (field === 'comments') setNewComments(value);

    memoized_debounce(field, value);

    if (field === 'hours' && value !== '' && ((parseFloat(value) * 4) % 1 !== 0 || parseFloat(value) > 24)) {
      if (valid) setValid(false);
      memoized_debounce.cancel({ upcomingOnly: true });
    } else {
      if (!valid) setValid(true)
    }
    
  }

  // When a blur event triggers, it could be that the pop-up closes, destroying the elements. 
  // So we track the changes in state and check what they changed, and send an update for each changed value
  const _handleBlur = (e: React.FocusEvent<HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement>) => {

    //if (entry.comments !== newComments) {
    //  // @ts-ignore
    //  dispatch(timesheetActions.updateEntry(entry.id, 'comments', newComments));
    //}
    //if (valid && entry.hours !== newHours) {
    //  // @ts-ignore
    //  dispatch(timesheetActions.updateEntry(entry.id, 'hours', newHours));
    //}
  }

  const isWeekend = new Date(entry.date).getDay() === 0 || new Date(entry.date).getDay() === 6;

  //const defaultElement = <input key={entry.hours} className={cx('form-control', locked && 'locked', isWeekend && 'weekend')} defaultValue={entry.hours} title={entry.comments} />;
  const defaultElement = <div key={entry.hours} className={cx('form-control default-element', locked && 'locked', isWeekend && 'weekend')} title={entry.comments}>{entry.hours}</div>;

  //React.useEffect


  return locked ? defaultElement : <PointedPopup defaultEle={defaultElement} leftAlign={true} className={cx('timesheet-entry')}>
    <div className='form-group hours-group'>
      <p><b>Hours</b></p>
      <input
        autoFocus
        className={cx('form-control', !valid && 'error')}
        style={{ width: '100px' }}
        value={newHours}
        type='number'
        onChange={_handleChange}
        onBlur={_handleBlur}
        name='hours'
        disabled={locked}
      />
      {!valid && <p><small style={{ color: 'red' }}>Time entry must be multiples of quarter hours and less than 24 hours</small></p>}
    </div>
    <div className='form-group' style={{marginBottom: '0.5rem'}}>
      <p><b>Comments</b></p>
      <textarea className='form-control' rows={3} value={newComments} onBlur={_handleBlur} onChange={_handleChange} name='comments' disabled={locked} />
    </div>
    <small style={{ fontSize: '85%'}}>Values automatically save as you're typing</small>
    {/*<button className='btn btn-sm btn-success' style={{ float: 'right' }}>Save</button>*/}
  </PointedPopup>
}
