import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IConfigurableDropdown,
  JobPhaseTask,
  LineItemDescription,
  ListItem,
} from '../../interfaces/interfaces';
import { ApplicationState, AppThunkAction } from '../../store';
import * as DropdownStore from '../../store/dropdowns';
import FilterableSelect from '../FilterableSelect';

export const JobsConfig = () => {
  const dispatch = useDispatch();
  const store = useSelector((s: ApplicationState) => s.dropdowns);
  const [jobs, setJobs] = React.useState<ListItem[]>([]);

  const [selectedJob, setSelectedJob] = React.useState(
    undefined as JobPhaseTask | undefined
  );
  const [selectedDescription, setSelectedDescription] = React.useState(
    undefined as LineItemDescription | undefined
  );

  const _addDescription = () => {
    const value = (
      document.querySelector('#new-description-input') as HTMLInputElement
    ).value;
    if (value) {
      dispatch(
        // @ts-ignore
        DropdownStore.actionCreators.addDescription(
          // @ts-ignore
          (selectedJob || { id: 0 }).id,
          value
        )
      );
    } else {
      dispatch({
        type: 'UPDATE_MESSAGE',
        message: 'Error: Please enter a non-empty value.',
      });
    }
  };

  const _addJob = () => {
    const value = (document.querySelector('#new-job-input') as HTMLInputElement)
      .value;
    if (value) {
      // @ts-ignore
      dispatch(DropdownStore.actionCreators.addJob(value));
    } else {
      dispatch({
        type: 'UPDATE_MESSAGE',
        message: 'Error: Please enter a non-empty value.',
      });
    }
  };

  const j = selectedJob || { id: 0, name: '', type: 0, lineItems: [] };
  const d = selectedDescription || { id: 0, name: '', billingRate: undefined };

  React.useEffect(() => {
    if (store.jobs && store.jobs.length > 0) {
      setJobs(
        store.jobs.map((x) => {
          return new ListItem({ id: x.id, name: x.name });
        })
      );

      if (selectedJob) {
        const job = store.jobs.find((x) => x.id === selectedJob.id);
        if (job) {
          setSelectedJob(job);
        }
      }
    }
  }, [store.jobs]);

  return (
    <div className="config-content jobs-config">
      <div className="config-tile">
        <h5>Edit Existing Job / Phase / Task</h5>
        <hr />
        <>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '25%', marginRight: '10px' }}>
              <b>Job</b>
              <FilterableSelect
                id="jobs-dropdown"
                items={jobs}
                defaultVal={j.id}
                onChange={(id) => {
                  setSelectedJob(
                    store.jobs.find((x) => x.id.toString() === id)
                  );
                }}
              />
            </div>
            <div style={{ width: '25%', marginRight: '10px' }}>
              <b>Name</b>
              {/* @ts-ignore */}
              <input
                key={j.id}
                className="form-control"
                defaultValue={j.name}
                onBlur={
                  selectedJob
                    ? (e) => {
                        if (e.currentTarget.value) {
                          dispatch(
                            // @ts-ignore
                            DropdownStore.actionCreators.editJob(
                              j.id,
                              'name',
                              e.currentTarget.value
                            )
                          );
                        } else {
                          dispatch({
                            type: 'UPDATE_MESSAGE',
                            message: 'Error: Please enter a non-empty value.',
                          });
                        }
                      }
                    : () => {}
                }
              />
            </div>
            <div style={{ width: '25%', marginRight: '10px' }}>
              <b>Type</b>
              <select
                className="form-control"
                id="jobs-type"
                value={j.type}
                onChange={
                  selectedJob
                    ? (e) => {
                        dispatch(
                          // @ts-ignore
                          DropdownStore.actionCreators.editJob(
                            j.id,
                            'type',
                            e.currentTarget.value
                          )
                        );
                      }
                    : () => {}
                }
              >
                <option value={0}>CAPEX</option>
                <option value={1}>OPEX</option>
                <option value={2}>OH</option>
              </select>
            </div>
          </div>
          <hr />
          <div style={{ width: 'calc(25% + 56px)', marginRight: '10px' }}>
            <h5>Add Job / Phase / Task</h5>
            <input
              className="form-control"
              id="new-job-input"
              style={{ width: 'calc(100% - 56px)' }}
            />
            <button
              className="btn btn-sm btn-success"
              onClick={_addJob}
              style={{ float: 'right', marginTop: '-2.25em' }}
            >
              Save
            </button>
          </div>
        </>
      </div>
      <div className="config-tile">
        <h5>Edit Descriptions For Job</h5>
        <hr />
        {selectedJob === undefined ? (
          <i>Select a job to continue</i>
        ) : (
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '25%', marginRight: '10px' }}>
                <b>Description</b>
                <FilterableSelect
                  id="description-dropdown"
                  items={store.descriptions
                    .filter((x) => x.jobPhaseTaskId === selectedJob.id)
                    .map((x) => {
                      return { id: x.id, label: x.name } as ListItem;
                    })}
                  onChange={(id) => {
                    setSelectedDescription(
                      store.descriptions.find((x) => x.id.toString() === id)
                    );
                  }}
                  defaultVal={d.id}
                />
              </div>
              <div style={{ width: '25%', marginRight: '10px' }}>
                <b>Name</b>
                {/* @ts-ignore */}
                <input
                  key={d.id}
                  className="form-control"
                  defaultValue={d.name}
                  onBlur={
                    selectedDescription
                      ? (e) =>
                          dispatch(
                            // @ts-ignore
                            DropdownStore.actionCreators.editDescription(
                              d.id,
                              'name',
                              e.currentTarget.value
                            )
                          )
                      : () => {}
                  }
                />
              </div>
              <div style={{ width: '25%', marginRight: '10px' }}>
                <b>Billing Rate</b>
                {/* @ts-ignore */}
                <input
                  key={d.id}
                  className="form-control"
                  defaultValue={
                    d.billingRate ? d.billingRate.toFixed(2) : d.billingRate
                  }
                  onBlur={
                    selectedDescription
                      ? (e) =>
                          dispatch(
                            // @ts-ignore
                            DropdownStore.actionCreators.editDescription(
                              d.id,
                              'billingRate',
                              e.currentTarget.value
                            )
                          )
                      : () => {}
                  }
                />
              </div>
            </div>
            <hr />
            <div style={{ width: 'calc(25% + 56px)', marginRight: '10px' }}>
              <h5>Add Descripton for {selectedJob.name}</h5>
              <input
                className="form-control"
                id="new-description-input"
                style={{ width: 'calc(100% - 56px)' }}
              />
              <button
                className="btn btn-sm btn-success"
                onClick={_addDescription}
                style={{ float: 'right', marginTop: '-2.25em' }}
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
