import { getDirectorMonthlyReport, getDirectReportTimesheets } from "../../services/reports";
import { startAppListening } from "../listenerMiddleware";
import { ReportsActions } from "./reports.reducer";

startAppListening({
	actionCreator: ReportsActions.getSupervisorReports,
	effect: async (action, listenApi) => {
		listenApi.dispatch(ReportsActions.setSelectedDate(action.payload));
		const data = await getDirectReportTimesheets(action.payload);
		if (data) {
			listenApi.dispatch(ReportsActions.getSupervisorReportsSuccess(data));
		}
	},	
});

startAppListening({
	actionCreator: ReportsActions.getDirectorMonthlyReport,
	effect: async (action, listenApi) => {
		listenApi.dispatch(ReportsActions.setSelectedMonth(action.payload));
		const data = await getDirectorMonthlyReport(action.payload);
		if (data) {
			listenApi.dispatch(ReportsActions.getDirectorMonthlyReportSuccess(data));
		}
	},
});
