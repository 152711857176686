import * as React from 'react'
import './tabs.css';

const findDefaultTab = (children: any, defaultTab: string) => {
    let firstLink: any, firstDefaultLink: any
    const links: string[] = [];

    const traverse = (child: any) => {
        if (!child || !child.props) {
            return;
        }

        if (child.type.displayName === 'TabLink') {
            links.push(child.props.to)
            firstDefaultLink = firstDefaultLink || defaultTab || child.props.to
        }

        React.Children.forEach(child.props.children, traverse)
    }

    React.Children.forEach(children, traverse)

    const hashTabName = document.location.hash.substr(1);
    if (links.indexOf(hashTabName) !== -1) firstDefaultLink = hashTabName;

    return firstDefaultLink || firstLink
}

type TabsProps = {
    name: string,
    tabComponent?: string,
    children?: any,
    onChange?: any,
    handleSelect?: () => void,
    selectedTab?: string,
    activeLinkStyle?: object,
    visibleTabStyle?: object,
    disableInlineStyles?: boolean,
    renderActiveTabContentOnly?: boolean,
    defaultTab?: string,
    recallTab?: string | undefined
}

export class Tabs extends React.Component<TabsProps, any> {
    // findDefaultTab 2nd argument is string to recall back to the previous tab
    state = {
        selectedTab: findDefaultTab(this.props.children, this.props.defaultTab || this.props.recallTab || "")
    }

    componentDidMount() {
        if (this.props.onChange) this.props.onChange(this.state.selectedTab, this.props.name)
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.selectedTab !== prevState.selectedTab && this.props.onChange) {
            this.props.onChange(this.state.selectedTab, this.props.name)
        }
    }

    componentWillReceiveProps(newProps: TabsProps) {
        if (this.props.selectedTab !== newProps.selectedTab) {
            this.setState({
                selectedTab: newProps.selectedTab
            })
        }
    }

    handleSelect = (tab: string) => {
        this.setState({
            selectedTab: tab
        })
    }

    transformChildren(
        children: any,
        {
            handleSelect,
            selectedTab,
            activeLinkStyle,
            visibleTabStyle,
            disableInlineStyles,
            name,
            tabComponent
        }: any
    ): any {
        if (typeof children !== 'object') {
            return children
        }

        return React.Children.map(children, (child: any) => {
            if (!child) {
                return child
            }

            if (!child.type) return []

            if (child.type.displayName == 'TabLink') {
                return React.cloneElement(child, {
                    handleSelect,
                    isActive: child.props.to === selectedTab,
                    //activeStyle: activeLinkStyle,
                    disableInlineStyles,
                    namespace: name,
                    component: child.props.component || tabComponent
                })
            }

            if (child.type.displayName === 'TabContent') {
                return React.cloneElement(child, {
                    isVisible: child.props.for === selectedTab,
                    visibleStyle: visibleTabStyle,
                    disableInlineStyles,
                    renderActiveTabContentOnly: this.props.renderActiveTabContentOnly
                })
            }

            if (child.type.displayName === 'TabGroup') {
                const selectedTabIndex = child.props.children.map((x: any) => {
                    if (x && x.props) {
                        if (x.props.to !== undefined) return x.props.to;
                        else if (x.props.children.props && x.props.children.props.to) {
                            return x.props.children.props.to;
                        }
                    }
                    return ""
                }).indexOf(selectedTab);
                const selectedChild = child.props.children[selectedTabIndex];
                let selectedTabText = '';
                if (selectedChild) {
                    let tempProps = selectedChild.props.children;
                    while (tempProps && tempProps.props) {
                        selectedTabText = tempProps.props.children;
                        tempProps = tempProps.props.children;
                    }
                } 
                return React.cloneElement(child, {
                    isActive: selectedTabIndex !== -1,
                    selectedTabText: selectedTabText,
                    activeStyle: activeLinkStyle,
                    disableInlineStyles,
                },
                    this.transformChildren(child.props && child.props.children, {
                        handleSelect,
                        selectedTab,
                        activeLinkStyle,
                        visibleTabStyle,
                        disableInlineStyles,
                        name,
                        tabComponent
                    })
                )
            }

            return React.cloneElement(
                child,
                {},
                this.transformChildren(child.props && child.props.children, {
                    handleSelect,
                    selectedTab,
                    activeLinkStyle,
                    visibleTabStyle,
                    disableInlineStyles,
                    name,
                    tabComponent
                })
            )
        })
    }


    render() {
        const {
            handleSelect: handleSelectProp,
            selectedTab: selectedTabProp,
            activeLinkStyle,
            visibleTabStyle,
            disableInlineStyles,
            name,
            renderActiveTabContentOnly,
            tabComponent,
            recallTab,
            ...divProps
        } = this.props;
        const handleSelect = handleSelectProp || this.handleSelect

        const children = this.transformChildren(this.props.children, {
            handleSelect,
            selectedTab: this.state.selectedTab,
            activeLinkStyle,
            visibleTabStyle,
            disableInlineStyles,
            name,
            tabComponent,
        })

        return <div {...divProps}>{children}</div>
    }
}