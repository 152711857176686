import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IConfigurableDropdown,
  LineItemDescription,
  ListItem,
  ToolEntity,
} from '../../interfaces/interfaces';
import { ApplicationState, AppThunkAction } from '../../store';
import * as DropdownStore from '../../store/dropdowns';
import FilterableSelect from '../FilterableSelect';

export const ToolsConfig = () => {
  const dispatch = useDispatch();
  const tools = useSelector((s: ApplicationState) => s.dropdowns.tools);

  const [selectedTool, setSelectedTool] = useState<ToolEntity>({
    id: 0,
    name: '',
    lineItems: [],
    billableName: '',
    poLocation: '',
  });
  const [toolItems, setToolItems] = useState<ListItem[]>([]);

  const _addTool = () => {
    const value = (
      document.querySelector('#new-tool-input') as HTMLInputElement
    ).value;
    if (value) {
      // @ts-ignore
      dispatch(DropdownStore.actionCreators.addTool(value));
    } else {
      dispatch({
        type: 'UPDATE_MESSAGE',
        message: 'Error: Please enter a non-empty value.',
      });
    }
  };

  useEffect(() => {
    if (tools && tools.length > 0) {
      setToolItems(
        tools.map((x) => {
          return new ListItem({ id: +x.id, name: x.name });
        })
      );
      if (selectedTool.id > 0) {
        const tool = tools.find((x) => x.id === selectedTool.id);
        if (tool) {
          setSelectedTool(tool);
        }
      }
    }
  }, [tools]);

  return (
    <div className="config-content jobs-config">
      <div className="config-tile">
        <h5>Edit Existing Tool</h5>
        <hr />
        <>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '25%', marginRight: '10px' }}>
              <b>Tool</b>
              <FilterableSelect
                id="jobs-dropdown"
                items={toolItems}
                defaultVal={selectedTool.name}
                onChange={(id) =>
                  setSelectedTool(
                    tools.find((x) => x.id.toString() === id) || {
                      id: 0,
                      name: '',
                      billableName: '',
                      poLocation: '',
                      lineItems: [],
                    }
                  )
                }
              />
            </div>
            <div style={{ width: '25%', marginRight: '10px' }}>
              <b>Name</b>
              {/* @ts-ignore */}
              <input
                key={selectedTool.id}
                className="form-control"
                defaultValue={selectedTool.name}
                onBlur={
                  selectedTool.id > 0
                    ? (e) => {
                        if (e.currentTarget.value) {
                          dispatch(
                            // @ts-ignore
                            DropdownStore.actionCreators.editTool(
                              selectedTool.id,
                              'name',
                              e.currentTarget.value
                            )
                          );
                        } else {
                          dispatch({
                            type: 'UPDATE_MESSAGE',
                            message: 'Error: Please enter a non-empty value.',
                          });
                        }
                      }
                    : () => {}
                }
              />
            </div>
            <div style={{ width: '25%', marginRight: '10px' }}>
              <b>Billable Name</b>
              {/* @ts-ignore */}
              <input
                key={selectedTool.id}
                className="form-control"
                defaultValue={selectedTool.billableName}
                onBlur={
                  selectedTool.id > 0
                    ? (e) =>
                        dispatch(
                          // @ts-ignore
                          DropdownStore.actionCreators.editTool(
                            selectedTool.id,
                            'billableName',
                            e.currentTarget.value
                          )
                        )
                    : () => {}
                }
              />
            </div>
            <div style={{ width: '25%', marginRight: '10px' }}>
              <b>PO Location</b>
              {/* @ts-ignore */}
              <input
                maxLength={4}
                key={selectedTool.id}
                className="form-control"
                defaultValue={selectedTool.poLocation}
                onBlur={
                  selectedTool.id > 0
                    ? (e) =>
                        dispatch(
                          // @ts-ignore
                          DropdownStore.actionCreators.editTool(
                            selectedTool.id,
                            'pOLocation',
                            e.currentTarget.value
                          )
                        )
                    : () => {}
                }
              />
            </div>
          </div>
          <hr />
          <div style={{ width: 'calc(25% + 56px)', marginRight: '10px' }}>
            <h5>Add Tool</h5>
            <input
              className="form-control"
              id="new-tool-input"
              style={{ width: 'calc(100% - 56px)' }}
            />
            <button
              className="btn btn-sm btn-success"
              onClick={_addTool}
              style={{ float: 'right', marginTop: '-2.25em' }}
            >
              Save
            </button>
          </div>
        </>
      </div>
    </div>
  );
};
