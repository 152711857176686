import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

import { EmployeeInfoVm } from '../interfaces/interfaces';
import { authGet } from '../auth/authFetch';
import { MessageAction } from './message';


export interface AlertStore {
    alerts: UserAlert[]
}

export interface UserAlert {
    message: string;
    index: number;
    dismissed: boolean;
}

interface RequestAlertsAction {
    type: 'REQUEST_ALERTS'
}

interface ReceiveAlertsAction {
    type: 'RECEIVE_ALERTS',
    alerts: UserAlert[]
}

interface DismissAlertAction {
    type: 'DISMISS_ALERT',
    alerts: UserAlert[]
}

export type KnownAction = RequestAlertsAction | ReceiveAlertsAction | DismissAlertAction | MessageAction;


export const actionCreators = {
    getAlerts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (!getState().alerts.alerts.length) {
            authGet(`api/alert/get`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) dispatch({ type: 'RECEIVE_ALERTS', alerts: data.map((x: string, i: number) => { return { message: x, index: i, dismissed: false } as UserAlert }) });
                    else dispatch({ type: 'UPDATE_MESSAGE', message: data.message || 'Error occured getting user information' })
                });

            dispatch({ type: 'REQUEST_ALERTS' });
        }
    },
    dismiss: (index: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (getState().alerts.alerts) {
            const dismissed = getState().alerts.alerts.slice().map((x, i) => {
                if (i === index) return { ...x, dismissed: true };
                return x;
            })

            dispatch({ type: 'RECEIVE_ALERTS', alerts: dismissed })
        }
    }
}

const DefaultState: AlertStore = {
    alerts: [] as UserAlert[]
}

export const reducer: Reducer<AlertStore> = (state: AlertStore | undefined, incomingAction: Action): AlertStore => {

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ALERTS':
            return {
                ...state || DefaultState,
            };
        case 'RECEIVE_ALERTS':
            return {
                ...state, alerts: action.alerts
            };
        default: {
            // @ts-ignore
            const exhaustiveCheck: never = action
        }
    }

    return state || DefaultState;
} 