import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./config";

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType: any) => {
        if (loginType === 'popup') {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            })
        } else if (loginType === 'redirect') {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            })
        }
    }

    return <button className='btn btn-md btn-outline-secondary ml-auto' style={{ width: '100%', textAlign: 'center' }} onClick={() => handleLogin('redirect')}>Sign In</button>
}
