import { createSelector } from "@reduxjs/toolkit";
import { ApplicationState } from "..";
import { ReportsState } from "./reports.reducer";

const reportsState = (state: ApplicationState): ReportsState => state.reports;

export const supervisorReportSelector = createSelector(
	reportsState,
	(state) => state.supervisorReport
);

export const employeeTimesheetReportsSelector = createSelector(
	supervisorReportSelector,
	(supervisorReport) => supervisorReport && (supervisorReport.employeeTimesheetReports || [])
);

export const toolReportsSelector = createSelector(
	supervisorReportSelector,
	(supervisorReport) => supervisorReport && (supervisorReport.toolReports || [])
);

export const monthlyReportSelector = createSelector(
	reportsState,
	(state) => state.monthlyReport
);

export const selectedEndDateSelector = createSelector(
	reportsState,
	(state) => state.selectedEndDate
);

export const selectedMonthSelector = createSelector(
	reportsState,
	(state) => state.selectedMonth
);
