import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  IConfigurableDropdown,
  ListItem,
  TimesheetLineItem,
} from '../../interfaces/interfaces';
import { ApplicationState } from '../../store';
import { actionCreators as timesheetActions } from '../../store/timesheet';
import FilterableSelect from '../FilterableSelect';
import { TimesheetEntryComponent } from './TimesheetEntry';
import styled from 'styled-components';
import $ from 'jquery';
import { Draggable } from '@hello-pangea/dnd';

const FilterableSelectContatiner = styled.div<{ width: string; top: string }>(
  (props) => ({
    '& ul': {
      position: 'fixed',
      top: props.top,
      width: props.width,
    },
    '& input:invalid': {
      borderColor: '#dc3545',
      ':focus': { boxShadow: '0 0 0 0.25rem rgb(220 53 69 / 25%)' },
    },
  })
);

export const TimesheetLineComponent = (props: {
  line: TimesheetLineItem;
  locked: boolean;
  bodyScrollTop: number;
  index: number;
  id: string;
}) => {
  const { line, locked, bodyScrollTop, index, id } = props;

  const dropdowns = useSelector((s: ApplicationState) => s.dropdowns);
  const dispatch = useDispatch();
  const [top, setTop] = React.useState('0px');
  const [jobWidth, setJobWidth] = React.useState('100px');
  const [toolWidth, setToolWidth] = React.useState('100px');
  const [descWidth, setDescWidth] = React.useState('100px');

  const tools = dropdowns.tools.map((x) => {
    return new ListItem({ id: +x.id, name: x.name });
  });

  const jobs = dropdowns.jobs.map((x) => {
    return new ListItem({ id: x.id, name: x.name });
  });

  const _handleDropdown = (field: string, value: any) => {
    // @ts-ignore
    dispatch(timesheetActions.updateLineItem(line.id, field, value));
  };

  const jobRef = React.useRef<HTMLDivElement>(null);
  const toolRef = React.useRef<HTMLDivElement>(null);
  const descRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (jobRef.current)
      setJobWidth(
        (jobRef.current.firstChild as HTMLDivElement).offsetWidth + 'px'
      );
    if (toolRef.current)
      setToolWidth(
        (toolRef.current.firstChild as HTMLDivElement).offsetWidth + 'px'
      );
    if (descRef.current)
      setDescWidth(
        (descRef.current.firstChild as HTMLDivElement).offsetWidth + 'px'
      );
  }, [jobRef.current, toolRef.current, descRef.current]);

  React.useEffect(() => {
    if (jobRef.current) {
      const value =
        (jobRef.current.getBoundingClientRect().top || 0) +
        (jobRef.current.offsetHeight || 0) +
        'px';
      setTop(value);
      $('body')[0].click();
    }
  }, [bodyScrollTop]);

  const toolRequired = React.useMemo(() => {
    return line.jobPhaseTask
      ? line.jobPhaseTask.name.endsWith('.0030') ||
          line.jobPhaseTask.name.endsWith('.0040')
      : false;
  }, [line]);

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="timesheet-line"
        >
          <FilterableSelectContatiner
            top={top}
            width={jobWidth}
            className="my-col-3"
            ref={jobRef}
          >
            <FilterableSelect
              id={`line-item-job-${line.id}`}
              items={jobs}
              defaultVal={line.jobPhaseTaskId}
              readonly={locked}
              onChange={(id) => _handleDropdown('jobPhaseTaskId', id)}
            />
          </FilterableSelectContatiner>
          <FilterableSelectContatiner
            top={top}
            width={toolWidth}
            className="my-col-2"
            ref={toolRef}
          >
            <FilterableSelect
              id={`line-item-tool-${line.id}`}
              items={tools}
              defaultVal={line.toolId}
              readonly={locked}
              onChange={(id) => _handleDropdown('toolId', id)}
              allowEmpty={true}
              required={toolRequired}
            />
          </FilterableSelectContatiner>
          <FilterableSelectContatiner
            top={top}
            width={descWidth}
            className="my-col-4"
            ref={descRef}
          >
            <FilterableSelect
              id={`line-item-description-${line.id}`}
              items={dropdowns.descriptions
                .filter((x) => x.jobPhaseTaskId === line.jobPhaseTaskId)
                .map((x) => {
                  return { id: x.id, label: x.name } as ListItem;
                })}
              defaultVal={line.descriptionId}
              readonly={locked}
              onChange={(id) => _handleDropdown('descriptionId', id)}
              required={true}
            />
          </FilterableSelectContatiner>
          {line.entries.map((x) => {
            return (
              <div key={x.id} className="my-col-1">
                <TimesheetEntryComponent entry={x} locked={locked} />
              </div>
            );
          })}
          <div className="my-col-3"></div>
          <div className="my-col-1">
            {/* @ts-ignore */}
            <i
              className="btn"
              // @ts-ignore
              onClick={() => dispatch(timesheetActions.deleteLineItem(line.id))}
              title="Remove line"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-trash3-fill"
                viewBox="0 0 16 16"
              >
                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
              </svg>
            </i>
          </div>
          <div
            className="my-col-1"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div {...provided.dragHandleProps}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-grip-vertical"
                viewBox="0 0 16 16"
              >
                <title>Move line</title>
                <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
