import React, { useEffect, useState } from "react";
import { authGet } from "../../auth/authFetch";
import DatePicker from "react-datepicker";
import { formatDate } from "../timesheet/Timesheet";
import { useDispatch } from "react-redux";
import {
    employeeTimesheetReportsSelector,
    ReportsActions,
    selectedEndDateSelector,
    toolReportsSelector,
} from "../../store/reports";
import { useSelector } from "react-redux";
import styled from "styled-components";

const StyledColumn = styled.div({
    width: '50%',
    padding: '8px 16px',
    minHeight: '80vh',
})

const StyledContainer = styled.div({
    border: "1px solid rgb(140, 140, 140)",
    borderRadius: "0px 0px 2px 2px",
    width: "100%",
    margin: "10px 0px",
});

const StyledTableHeader = styled.div({
    padding: "12px 0px",
    borderBottom: "1px solid lightgray",
    "&:last-of-type": {
        borderBottom: "none",
    },
    fontWeight: 'bold',
    backgroundColor: 'rgba(230, 230, 240, 0.3)',
    display: 'flex'
})

const StyledRow = styled.div({
    padding: "12px 0px",
    borderBottom: "1px solid lightgray",
    "&:last-of-type": {
        borderBottom: "none",
    },
    display: 'flex'
});

export const SupervisorReports: React.FC = () => {
    const [selectedEndDate, setSelectedEndDate] = useState<string | undefined>(undefined);
    const employeeReports = useSelector(employeeTimesheetReportsSelector);
    const toolReports = useSelector(toolReportsSelector);
    const selectedDate = useSelector(selectedEndDateSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedEndDate) {
            dispatch(ReportsActions.getSupervisorReports(selectedEndDate));
        }
    }, [selectedEndDate]);

    return (
        <>
            <div style={{ display: "flex", marginBottom: "20px" }}>
                {/*<b style={{ width: "80px", marginTop: '0.2em' }}>End Date</b>*/}
                <DatePicker
                    key={new Date().toISOString()}
                    onChange={(date) => date ? setSelectedEndDate(date.toISOString()) : setSelectedEndDate(undefined)}
                    placeholderText="Select a week"
                    value={selectedDate}
                    filterDate={(date) => date.getDay() === 6}                    
                />
            </div>
            <div style={{ display: "flex", alignItems: "start" }}>
                <StyledColumn style={{ borderRight: '1px solid lightgray', marginLeft: '-16px' }}>
                    <h5>Employee Report</h5>
                    {employeeReports && employeeReports.length > 0 && (
                        <StyledContainer>
                            <>
                                <StyledTableHeader>
                                    <div className="col-sm">Employee Name</div>
                                    <div className="col-sm">Total Hours</div>
                                </StyledTableHeader>
                                {employeeReports.map((x) => (
                                    <StyledRow>
                                        <div className="col-sm">{x.fullName}</div>
                                        {x.timesheet ? (
                                            <div className="col-sm">{x.timesheet.totalHours}</div>
                                        ) : (
                                            <div className="col-sm">0</div>
                                        )}
                                    </StyledRow>
                                ))}
                            </>
                        </StyledContainer>
                    )}
                </StyledColumn>
                <StyledColumn>
                    <h5>Tool Usage Report</h5>
                    {toolReports && toolReports.length > 0 ? (
                        <StyledContainer>
                            <>
                                <StyledTableHeader>
                                    <div className="col-sm">Tool Name</div>
                                    <div className="col-sm">Total Hours</div>
                                </StyledTableHeader>
                                {toolReports.map((x) => (
                                    <StyledRow>
                                        <div className="col-sm">{x.name}</div>
                                        <div className="col-sm">{x.timesheet.totalHours}</div>
                                    </StyledRow>
                                ))}
                            </>
                        </StyledContainer>
                    ) : <div><i>No hours found allocated to tools for the selected week</i></div>}
                </StyledColumn>
            </div>
        </>
    );
};
