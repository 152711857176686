import {
	createListenerMiddleware,
	TypedStartListening,
	addListener,
	TypedAddListener,
} from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./";

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening =
	listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<
	RootState,
	AppDispatch
>;
