import * as Alerts from "./alerts";
import * as Dropdowns from "./dropdowns";
import * as Message from "./message";
import * as User from "./user";
import * as Timesheet from "./timesheet";
import { ReportsState, ReportsReducer } from "./reports";
import { createBrowserHistory, LocationState } from "history";
import thunk from "redux-thunk";
import {
	connectRouter,
	routerMiddleware,
	RouterState,
} from "connected-react-router";
import { AnyAction, configureStore, Reducer } from "@reduxjs/toolkit";
import { listenerMiddleware } from "./listenerMiddleware";

// The top-level state object
export interface ApplicationState {
	alerts: Alerts.AlertStore;
	dropdowns: Dropdowns.DropdownState;
	message: Message.MessageState;
	reports: ReportsState;
	timesheet: Timesheet.TimesheetStore;
	user: User.UserStore;
}

const baseUrl = document
	.getElementsByTagName("base")[0]
	.getAttribute("href") as string;
const history = createBrowserHistory({ basename: baseUrl });

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const rootReducer = {
	alerts: Alerts.reducer,
	dropdowns: Dropdowns.reducer,
	message: Message.reducer,
	reports: ReportsReducer,
	timesheet: Timesheet.reducer,
	user: User.reducer,
	router: connectRouter(history) as Reducer<RouterState, AnyAction>,
};

const store = configureStore({
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(thunk, routerMiddleware(history))
			.prepend(listenerMiddleware.middleware),
	reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
	(dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export default store;
