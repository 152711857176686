export interface EmployeeInfoVm {
  id: number;
  email: string;
  alternateEmail: string;
  employeeNumber: string;
  fullName: string;
  firstName: string;
  lastName: string;
  title: string;
  supervisorId: string;
  isSupervisor: boolean;
  status: EmployeeStatus;
  permissions: PermissionLevel[];
  jobTitleId: number;
}

export enum EmployeeStatus {
  Active = 1,
  Inactive = 999,
}

export interface EmployeeTimesheetReport {
  firstName: string;
  lastName: string;
  fullName: string;
  timesheet: TimesheetReportItem;
}

export interface IConfigurableDropdown {
  id: number;
  name: string;
}

export interface JobPhaseTask {
  id: number;
  name: string;
  type: JobPhaseTaskType;
  lineItems: TimesheetLineItem[];
}

export enum JobPhaseTaskType {
  CAPEX,
  OPEX,
  OH,
}

export interface LineItemDescription extends IConfigurableDropdown {
  jobPhaseTaskId: number;
  billingRate: number;
}

export class ListItem {
  id: number | string;
  label: string;
  description: string;

  constructor(c: IConfigurableDropdown) {
    this.id = c.id;
    this.label = c.name;
    this.description = '';
  }
}

export interface ToolEntity {
  id: number;
  name: string;
  billableName: string;
  poLocation: string;
  lineItems: TimesheetLineItem[];
}

export enum PermissionLevel {
  User = 0,
  PowerUser = 9,
  Director = 99,
}

export interface SupervisorTimesheetReport {
  employeeTimesheetReports: EmployeeTimesheetReport[];
  toolReports: ToolReport[];
}

export interface DirectorMonthlyReport {
  lines: MonthlyReportLine[];
  totalCost: number;
  directCost: number;
  indirectCost: number;
  invoiceRollup: MonthlyInvoiceRollup;
  jobTitleRollup: MonthlyJobTitleRollup;
  totalHoursRollup: MonthlyTotalHoursRollup;
}

export interface MonthlyReportLine {
  toolOrDescription: string;
  toolPO: string;
  capexTotalHrs: number;
  opexTotalHrs: number;
  ohTotalHrs: number;
  cost: number;
  percentOfCapital: number;
  indirectAdd: number;
  totalCost: number;
  direct: boolean;
}

export interface MonthlyInvoiceRollup {
  rows: InvoiceRollupRow[];
}

export interface MonthlyJobTitleRollup {
  rows: JobTitleRollupRow[];
}

export interface MonthlyTotalHoursRollup {
  rows: TotalHoursRollupRow[];
}

export interface InvoiceRollupRow {
  name: string;
  amount: number;
}

export interface JobTitleRollupRow {
  lineItemName: string;
  lineItemPO: string;
  description: string;
  hours: number;
  amount: number;
}

export interface TotalHoursRollupRow {
  employeeName: string;
  totalHours: string;
  isLessHoursThanExpected: boolean;
}

export interface Timesheet {
  id: number;
  employeeId: number;
  employee: EmployeeInfoVm;
  employeeName: string;
  startDate: Date;
  endDate: Date;
  status: TimesheetStatus;
  lineItems: TimesheetLineItem[];
  lastModified: Date | null;
  submittedDate: Date | null;
}

export interface TimesheetEntry {
  id: number;
  timesheetId: number;
  timesheetLineItemId: number;
  date: Date;
  hours: number;
  comments: string;
  lastModified: Date | null;
}

export interface TimesheetLineItem {
  id: number;
  timesheetId: number;
  jobPhaseTaskId: number;
  descriptionId: number;
  toolId: number;
  order: number;
  jobPhaseTask: IConfigurableDropdown;
  description: LineItemDescription;
  toolEntity: ToolEntity;
  entries: TimesheetEntry[];
  timesheet: Timesheet;
}

export interface TimesheetReportItem {
  startDate: Date;
  endDate: Date;
  totalHours: number;
}

export enum TimesheetStatus {
  Pending,
  Submitted,
  Posted,
}

export interface ToolReport {
  name: string;
  timesheet: TimesheetReportItem;
}

export interface UpdateInfo {
  field: string;
  value: any;
}
