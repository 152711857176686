import React from "react";

import { TabLink, Tabs, TabContent } from "../tabs";
import { MyAuthElement } from "../../auth/WrappedAuthElement";
import { PermissionLevel } from "../../interfaces/interfaces";
import { SupervisorReports } from "./SupervisorReports";
import { DirectorReport } from "./DirectorReport";

import './reports.css';
import { JobsConfig } from "../config/JobsConfig";
import { ToolsConfig } from "../config/ToolsConfig";
import { EmployeeConfig } from "../config/EmployeeConfig";

export const ReportHome: React.FC = () => {

    const DirectorComponent = MyAuthElement([PermissionLevel.Director])

    return (
        <div className='reports-home'>
            <Tabs name='reports-tabs'>
                <div className='tab-links' style={{ display: 'flex' }}>
                    <TabLink to='supervisor' component='span'>
                        <span>Supervisor Reports</span>
                    </TabLink>
                    <DirectorComponent>
                        <TabLink to='director' component='span'>
                            <span>Final Monthly</span>
                        </TabLink>
                    </DirectorComponent>
                </div>
                <div className='content'>
                    <TabContent for='supervisor'>
                        <SupervisorReports />
                    </TabContent>
                    <TabContent for='director'>
                        <DirectorReport />
                    </TabContent>
                </div>
            </Tabs>
        </div>
    );
};
